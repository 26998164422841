 
/* WorkFlow Common Header Component CSS */
 
.workflow_common_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    margin-bottom: 2rem;
    padding-right: 1.7rem;
    padding-left: 1.7rem;
    background: #FDF4E7;
}

.workflow_common_header h2 {
    margin: 0; 
    font-size: 18px; 
    font-weight: 600;
    color: #303030;
    text-transform: capitalize;
}

.custom-dropdown-workflow {
    position: relative;
    display: inline-block;
}
  
.custom-dropdown-workflow .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.375rem 0.75rem;  
    font-weight: 600;
    font-size: 16px; 
    background-clip: padding-box;  
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.active-drp {
    color: #ffffff;
    background: #FF9800;
    border: 1px solid #FF9800; 
}

.default-drp, 
.default-drp:hover{
    color: #FF9800;
    background: rgba(255, 152, 0, 0.04);
    border: 1px solid #FF9800; 
}

.custom-dropdown-workflow .dropdown-toggle:focus {
    outline: none; 
}
  
.custom-dropdown-workflow .dropdown-menu {
    position: absolute;
    top: 50px;
    left: -112px;
    z-index: 1005;
    display: block;
    float: left;
    min-width: 240px;
    padding: 1rem 0;
    margin: 0.125rem 0 5rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid #DCDCDC;
    border-radius: 4px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
}

.custom-dropdown-workflow .dropdown-menu button:nth-child(even) {
    position: relative;
}
  
.custom-dropdown-workflow .dropdown-menu button:nth-child(even)::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0.5rem; 
    width: calc(100% - 1rem); 
    border-bottom:1px solid rgba(134, 134, 134, 0.24);
}

.custom-dropdown-workflow .dropdown-menu button:last-child::before {
    border-bottom: none;
}
  
.custom-dropdown-workflow .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    font-size: 14px;
    color: #757575;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    transition: 0.1s ease-in-out;
}
  
.custom-dropdown-workflow .dropdown-item:hover,
.custom-dropdown-workflow .dropdown-item:focus {
    color: #fff;
    background-color: #007bff;
    transition: 0.1s ease-in-out;
}

.custom-dropdown-workflow .arrow {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.3rem;
    vertical-align: middle;
    border-top: 0.3rem solid;
    border-right: 0.3rem solid transparent;
    border-bottom: 0;
    border-left: 0.3rem solid transparent;
    transition: transform 0.1s ease-in-out;
}

.custom-dropdown-workflow .up {
    transform: rotate(-135deg);
}
  
.custom-dropdown-workflow .down {
    transform: rotate(45deg);
}
 
.workflow_offcanvas_header .offcanvas-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.workflow_details {
    background: #DEE8F4;
    border-radius: 4px;
    height:auto;
    width: 100%;
    padding-left: 1.2rem;
    padding-top: 1.2rem;
    padding-bottom: 1rem;
}

.workflow_details h3 {
    color: #000000; 
    font-weight: 500;
    font-size: 14px;
}

.work_extra_details {
    margin-top: 1rem; 
}

.work_extra_details_chng {
    margin-bottom: 1rem;
} 

.work_extra_details h2, .work_extra_details_chng h2 {
    color: #2D74E0;
    font-size: 18px;
    font-weight: 700;
}

.workflow_details h3 span{
    color: #2D74E0;
    font-weight: 600;
    font-size: 14px;
}

.workflow_offcanvas_header .offcanvas-overlay.show {
    opacity: 1;
    z-index: 99999;
}

.blue {
    color: #2D74E0;
    background-color: #007bff;
}

.workflow_offcanvas_header .offcanvas-container {
    position: fixed;
    top: 0;
    right: -300px;
    width: 600px;
    height: 100%;
    border-radius: 8px 0px 0px 8px; 
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 1060;
}

.workflow_offcanvas_header .offcanvas-container.show {
    right: 0;
    z-index: 99999;
}

.workflow_offcanvas_header .offcanvas-container.transitioning {
    transition: right 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.workflow_offcanvas_header .offcanvas-content { 
    padding: 40px 30px 40px 30px;
    height: 100%;
}

.workflow_offcanvas_header .offcanvas-content .action-history-close-action-handler {
    position: absolute;
    left: -85px; 
    top: 50%;
}

.workflow_offcanvas_header .offcanvas-content .action-history-close-action-handler button {
    background-color: #fff;
    border: none;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    padding: 0;
    margin: 0;
}

.workflow_offcanvas_header .offcanvas-content .action_history .dropdown-menu-task {
    left: 26.5%;
    width: 300px;
}

.action_btns .submit {
    font-size: 16px;
    background: #FFFFFF;
    border: 1px solid #EBEFF2;
    border-radius: 4px;
    width: 124px;
    height: 40px;
}

/* .workflow_common_header .rs_btn {
    background-color: #FF98000A !important;
    color: #FF9800 !important;
    border: 1px solid #FF9800 ;
} */

.description_lbl{
    width: 100%;
    min-height: 100px;
    max-height: 120px;
    border: 1px solid #7E7E7E;
}
  

.input_details {
    margin-top: 0.8rem;
}

.input_details label {
    font-weight: 500;
}

.input_details h2 {
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #2D74E0;
    width: 160px;
}

.input_details .time-limit-heading {
    margin-bottom: 1rem;
    padding-bottom: 0.3rem;
    border-bottom: 2px solid #2D74E0;
    width: 86px !important; 
    font-size: 18px;
}

.description_lbl:focus {
    outline: none;
}

.action_btns {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
    background-color: transparent;
}

.workflow_offcanvas_header .action_btns .cancel {
    background: #FFFFFF;
    color: #303030;
}
  
.workflow_offcanvas_header .action_btns .submit {
    margin-left: 0.7rem;
    background: #2D74E0;
    color: #FFFFFF;
}

.workflow_offcanvas_header .offcanvas-close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    background-color: #ddd;
    border: none;
    cursor: pointer;
}

.rfi-module .rfi_module_wrapper,
.delegate-module .delegate_module_wrapper,
.task-reassign-module .task_reassign_module_wrapper {
    height: 100%;
    width: 100%;
} 

.rfi-module .rfi_module_wrapper::-webkit-scrollbar,
.delegate-module .delegate_module_wrapper::-webkit-scrollbar,
.task-reassign-module .task_reassign_module_wrapper::-webkit-scrollbar {
    width: 3px; 
}

.rfi-module .rfi_module_wrapper::-webkit-scrollbar-thumb,
.delegate-module .delegate_module_wrapper::-webkit-scrollbar-thumb,
.task-reassign-module .task_reassign_module_wrapper::-webkit-scrollbar-thumb {
    background-color: #2D74E0; 
    border-radius: 10px; 
}

.rfi-module .rfi_module_wrapper::-webkit-scrollbar-track,
.delegate-module .delegate_module_wrapper::-webkit-scrollbar-track,
.task-reassign-module .task_reassign_module_wrapper::-webkit-scrollbar-track{
    background-color: #ffffff; 
    border-radius: 10px; 
}

@media (max-height: 790px) {
    .rfi-module .rfi_module_wrapper,
    .delegate-module .delegate_module_wrapper,
    .task-reassign-module .task_reassign_module_wrapper {
        height: 420px;
        overflow-y: scroll;
        overflow-x: hidden;
    } 
}

@media (max-height: 680px) {
    .rfi-module .rfi_module_wrapper,
    .delegate-module .delegate_module_wrapper,
    .task-reassign-module .task_reassign_module_wrapper {
        height: 350px;
        overflow-y: scroll;
        overflow-x: hidden;
    } 
}  

@media (max-height: 600px) {
    .rfi-module .rfi_module_wrapper,
    .delegate-module .delegate_module_wrapper,
    .task-reassign-module .task_reassign_module_wrapper {
        height: 320px;
        overflow-y: scroll;
        overflow-x: hidden;
    } 
}

@media (max-height: 500px) {
    .rfi-module .rfi_module_wrapper,
    .delegate-module .delegate_module_wrapper,
    .task-reassign-module .task_reassign_module_wrapper {
        height: 230px;
        overflow-y: scroll;
        overflow-x: hidden;
    } 
}