.note-attchement-style {
  background: #222934;
  margin: -25px;
  padding: 20px;
  margin-bottom: 0px;
  border-radius: 10px 10px 0px 0px;
}


.note-inner-tab {
    border-bottom: none !important;;
}
.note-inner-tab .nav-tabs .nav-link {
    border: none !important;
    color: #000 !important;
}

.note-inner-tab .nav-tabs .nav-link.active {
    background-color: transparent !important;
  border-color: transparent !important;
    border-bottom: 2px solid #FC5E24 !important;
  color: #FC5E24 !important;
  font-weight: 700 !important;

}
.note-tab-wraper{
  padding-block: 10px !important;
  overflow: hidden; 
}

/* Hide scrollbar for Chrome, Safari and Opera */
.note-tab-wraper::-webkit-scrollbar {
width: 8px;
background-color: transparent;
transition: 0.6s;
}
.note-tab-wraper:hover::-webkit-scrollbar {
width: 8px;
transition: 0.8s;
}

.note-tab-wraper:hover::-webkit-scrollbar-thumb {
background-color: rgba(51, 49, 49, 0.295);
border-radius: 10px;
transition: 0.6s;
}
/* .note-tab-wraper::-webkit-scrollbar {
display: none;
}

.note-tab-wraper {
-ms-overflow-style: none;
scrollbar-width: none;
} */


.master_sidebar_radius{
  border-radius: 10px 10px 0px 0px;
}

.notes_widgets .note_lists .note_item .author_thumbnail {
  display: block;
  float: left;
  width: 47px;
  height: 47px;
  margin-right: 16px;
  border: 2px solid #000;
  border-radius: 50%;
  padding: 0;
  object-fit: cover;
}

/* for main sidebar component .................*/
.sidebar_section_inner {
width: 250px;
top: 0;
left: 0;
max-height: 100vh;
/* overflow: hidden; */
overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar_section_inner::-webkit-scrollbar {
display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar_section_inner {
-ms-overflow-style: none;
/* IE and Edge */
scrollbar-width: none;
/* Firefox */
}

.sidebar_section .sidebar_menu:hover::-webkit-scrollbar {
width: 8px;
background-color: transparent;
transition: 0.8s;
}

.sidebar_section .sidebar_menu:hover::-webkit-scrollbar-thumb {
background-color: rgba(255, 255, 255, 0.295);
border-radius: 10px;
transition: 0.6s;
}

::-webkit-scrollbar {
width: 8px;
background-color: transparent;
transition: 0.6s;
}

body:hover::-webkit-scrollbar {
width: 8px;
transition: 0.8s;
}
body::-webkit-scrollbar-thumb {
background-color: rgba(51, 49, 49, 0.295);
border-radius: 10px;
transition: 0.6s;
}

.ag-body-vertical-scroll-viewport::-webkit-scrollbar {
width: 8px;
height: 8px;
background-color: transparent;
transition: 0.6s;
}
.ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb {
background-color: rgba(51, 49, 49, 0.295);
border-radius: 10px;
transition: 0.6s;
}



.ag-body-horizontal-scroll-viewport::-webkit-scrollbar {
width: 8px;
height: 8px;
background-color: transparent;
transition: 0.6s;
}

/* .ag-body-horizontal-scroll-viewport:hover::-webkit-scrollbar {
width: 8px;
transition: 0.8s;
} */
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb {
background-color: rgba(51, 49, 49, 0.295);
border-radius: 10px;
transition: 0.6s;
}


/* for tab content left side .................. */

.left_side_detals_section {
overflow: hidden;
overflow-y: auto;
width: 100%;
/* transition: none; */
transition: 0.6s; 
}

/* Hide scrollbar for Chrome, Safari and Opera */
.left_side_detals_section::-webkit-scrollbar {
width: 8px;
background-color: transparent;
/* transition: 0.6s; */
}
.left_side_detals_section:hover::-webkit-scrollbar {
width: 8px;
/* transition: 0.8s; */
}

.left_side_detals_section:hover::-webkit-scrollbar-thumb {
background-color: rgba(51, 49, 49, 0.295);
border-radius: 10px;
/* transition: 0.6s; */
}

/* Hide scrollbar for IE, Edge and Firefox */
.left_side_detals_section {
/* -ms-overflow-style: none; */
/* IE and Edge */
/* scrollbar-width: none; */
/* Firefox */
}

.link_text_container {
cursor: pointer;
padding: 10px 0;
text-decoration: none;
/* border-bottom: 3px solid #FC5E24; */
/* box-shadow: 0px 11px 11px -5px rgba(171, 171, 171, 0.18); */

}

.link_text_container a {
text-decoration: none;

}

.active_tab {
border-bottom: 3px solid #FC5E24;
}

.active_tab p.link_text {
color: #FC5E24;
}

.super_nav_active_tab {
border-bottom: 3px solid #FC5E24;
}

.super_nav_active_tab p.link_text {
color: #FC5E24;
}

.tab_header {
display: flex;
gap: 0px;
flex-direction: column;
/* flex-wrap: wrap; */
/* border-bottom: 1px solid #DEE8F4; */

/* background-color: #fff; */
/* border-bottom: 1px solid #DEE8F4; */
/* box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15); */
padding: 0px 25px;
}

.tab_header.scrollUp_content {
padding: 0px 0px;

}

.tab_header .link_text {
display: block;
margin: 0;
padding: 0;
font-family: "Lato", sans-serif;
font-size: 16px;
font-weight: 400;
color: #58585A;
}

.super_nav_active_tab {
border-bottom: 3px solid #FC5E24;
}

.super_nav_active_tab p.link_text {
color: #FC5E24;
}
.tab_header_wraper {
border-bottom: 1px solid #DEE8F4;
margin-bottom: 5px;
box-shadow: 0px 11px 11px -5px rgba(171, 171, 171, 0.18);
}

.tab_header_container {
background-color: #fff;
/* padding: 0 0 10px 0; */
}

.tab_header_container.scrollUp {
/* box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15); */
box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.08);
}


/* bluecall to action section ................ */
.rs_calltoaction_section.nav_position_style_1 {
padding: 8px 20px;
}

.rs_calltoaction_section.nav_position_style_1 .actions_tags {
top: 18px;
}

.rs_calltoaction_section.nav_position_style_1 .rs_calltoaction_item {
display: flex !important;
flex-basis: auto !important;
align-items: baseline;
}

.rs_calltoaction_section.nav_position_style_1 .rs_calltoaction_item h3:after {
content: ':';
margin: 0 3px;
}

.note_attachment_history_widget {
padding: 25px 25px 10px 25px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .m_sidebar_items::-webkit-scrollbar {
display: none;
} */
.m_sidebar_items::-webkit-scrollbar {
width: 8px;
background-color: transparent;
transition: 0.6s;
}
.m_sidebar_items:hover::-webkit-scrollbar {
width: 8px;
transition: 0.8s;
}

.m_sidebar_items:hover::-webkit-scrollbar-thumb {
background-color: rgba(51, 49, 49, 0.295);
border-radius: 10px;
transition: 0.6s;
}

.rs_master_canvas_wraper{
padding-bottom: 0px !important;
}

.dashboard_container_wrapper {
transition: 0.6s;
opacity: 1;
}
.dashboard_container_wrapper.dash_nav_position_style_1 {
height: 0;
opacity: 0;
z-index: -1;
transition: 0.6s;
position: relative;
}

.scrollBarBlinkControl {  
  max-height: calc(100vh - 81px);
  overflow: hidden;
}
@media screen and (max-height:780px){
  .scrollBarBlinkDashboard .left_side_detals_section{
    max-height: 100% !important;
  }
   .scrollBarBlinkDashboard{
    overflow-y: auto;
   }
}
.scrollBarBlinkControlCommandSection {
  max-height: calc(100vh - 140px) ;
  overflow: hidden;
}

.textAreaHeightControl {
  min-height: 0px !important;
}

.rs_calltoaction_items.rs_calltoaction_items_width {
  width: 90% !important;
}

.control-label {
  color: #1F263E;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: 400;
  box-shadow: none !important;
}
.checkBoxControl input {
  border-radius: .25em;
  height: 16px;
  width: 16px;
  border: 2px solid #b4b2b2;
}
.checkBoxControl input:checked {
  background-color: #2196F3;
  border-color: #2196F3 !important;
}

.RfqHeaderControls-item > span {
  width: 2px;
  height: 16px;
  background-color: #b4b2b2;
}



.rfqCheckbox-style {
  width: 35px !important;
  height: 20px !important;
  margin-top: 6px;
  margin-left: -43px !important;
}

/* those are uses to fixed text line break */

.rfq-break-line span{
  white-space: nowrap;
  margin-right: 3px;
}
.rfq-singleline-text {
  white-space: pre;
}

/* those are uses for rfq history modal*/
.rfq-publish-history-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  background: #F5FAFF;
  padding: 11px;
  border-radius: 3px;
  .heading-field {
    color: #7e7e7e;
  }
}

/* change this because of scroll in vendor_cards_container in awarded page */
.awarding_parent .vendor_cards_container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  flex-direction: row;
  overflow: hidden;
  overflow-x: auto;
}
.vendor_cards_container::-webkit-scrollbar {
  width: 8px;
  height: 9px;
  background-color: transparent;
  transition: 0.6s;
  }
  .vendor_cards_container:hover::-webkit-scrollbar {
  width: 8px;
  height: 9px;
  transition: 0.8s;
  }

  .vendor_cards_container:hover::-webkit-scrollbar-thumb {
  background-color: rgba(51, 49, 49, 0.295);
  border-radius: 10px;
  transition: 0.6s;
  }

/* this for skeleton (when data is coming slow it will showing) */ 
/* start... */
  .button-skeleton {
    display: inline-block;
    width: 80px; /* Set the desired width */
    height: 35px; /* Set the desired height */
    background-color: #f0f0f0; /* Set the background color */
    border: none;
    border-radius: 4px;
    margin: 5px;
    position: relative;
    overflow: hidden;
  }
  
  .button-skeleton::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent 0%, #ddd 50%, transparent 100%);
    animation: loading 1.5s infinite;
  }
  .text-skeleton {
    display: inline-block;
    width: 80px; /* Set the desired width */
    height: 8px; /* Set the desired height */
    background-color: #f0f0f0; /* Set the background color */
    border: none;
    border-radius: 4px;
    margin: 5px;
    position: relative;
    overflow: hidden;
  }
  
  .text-skeleton::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent 0%, #ddd 50%, transparent 100%);
    animation: loading 1.5s infinite;
  }
  
  @keyframes loading {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }

  /* end... */


  .rfq-header-dropdown {
    display: none;
    width: auto !important;
  }
  .common-grid-responsive-btns{
    display: flex;
  }
    @media screen and (max-width: 1595px){
      .common-grid-responsive-btns{
        display: none;
      }
      .rfq-header-dropdown {
        display: block;
        width: auto !important;
      }
      .tab_menu_toggle_btn {                /* for styling Dropdown.Toggle */
        padding: 5px 6px !important;
        border: 1px solid #DCDCDC !important;
        display: flex;
        background-color: white !important;
        width: 34px !important
      }
      
      .rfq_droptown_toggle_img{ /* for styling Dropdown.Toggle image*/
        width: 20px 
      }
      .rfq_scoring_dropdown_menu { /*  for styling CommonScoringSections component's Dropdown.Menu */
        width: 200px;
        height: auto;
        padding: 10px
      }
      
      .rfq_vendor_dropdown_menu{ /*  for styling VendrsGrid component's Dropdown.Menu */
        width: 220px;
        height: 120px;
        padding: 10px;
      }
      .rfq_dropdown_item{ /* for DropdownItem */
        margin-bottom: 5px
      }
      .rfq_dropdown_item.dropdown-item > button { background-color: transparent; border: 0; }
    };

    .add_collaboration_team{
      white-space: nowrap !important;
    }

    .add_scoring_template{
      white-space: nowrap;
    }

    .action_item_header span{
      white-space: nowrap;
      margin-right: 3px;
    }
    .rs_header_controller{
      align-items: center;
      display: flex !important;
    }
    .rfq_tab_inner_toggle_btn{
      padding: 5px 6px !important;
      border: 1px solid #DCDCDC !important;
      display: flex;
      }
      .rfq_tab_inner_toggle_btn_width{
        max-width: none;
      }

      