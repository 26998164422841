 
.general_tabs .lynkaz_tab_nav_section{
    z-index: 100 !important;
}

 .lynkaz_tab_nav_section .rs-sticky-element .rs_secondery_header .rs_calltoaction_section {
    z-index: 1009 !important;
}

.sites_tabs .lynkaz_tab_nav_section{
    z-index: 100 !important;
}
  
.users_tabs .lynkaz_tab_nav_section{
    z-index: 100 !important;
}

.vendor-categories-pagination-buttons {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 1rem;
    gap: 5px;  
    user-select: none;
}

.vendor-categories-pagination-buttons button {
    background-color: transparent;  
    color: rgb(0, 0, 0);  
    border: none;   
    font-size: 15px;  
    cursor: pointer;   
    font-weight: 800;
}
 
.vendor-categories-pagination-buttons button:disabled {
    background-color: transparent;  
    color: #acacac;  
    cursor: not-allowed;  
}

.vendor-categories-pagination-buttons span {
    font-size: 14px;   
    color: #000000;  
}
 
.disable-img {
    opacity: 0.5;
}
  
.general_vn_site_sc { 
    overflow: auto;
    overflow-x: hidden;
    max-height: 100%;
}

.general_vn_site_sc::-webkit-scrollbar {
    width: 2px; /* width of the scrollbar */
}

.action_button_vn_category {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row; 
}

.action_button_search_category {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row; 
    gap: 15px; 
}

.action_button_search_category input {
   width: 100%;
   outline: none;
   font-size: 16px;
   border-top: 0;
   margin: 0;
   padding: 0; 
   border-left: 0;
   border-right: 0;
   border-bottom: 1px solid black;
}

.action_button_search_category input::placeholder {
    font-size: 16px; 
}

.action_button_search_category button { 
    border: none;
    font-size: 14px;
    background-color: transparent;
    color: #007bff;
    transition: 0.3s ease-in-out;
}

.action_button_search_category button:hover {
    color: #FC5E24;
    transition: 0.3s ease-in-out;
}

.action_button_search_category input:focus{
   outline: none;
}

.action_btns_categories button {
    border: none; 
    font-weight: 600; 
    font-size: 18px;
}

.vendor-category-wrapper {
    height: 250px; 
    overflow-y: auto;
}

.action_btns_categories button:disabled{
    opacity: 0.5;
}

.vendor_categories_popup {
    width: 100%;
    min-height: 375px; 
}

.general_vn_site_sc::-webkit-scrollbar-track {
    background-color: #f1f1f1; /*  background color of the scrollbar track */
}

.general_vn_site_sc::-webkit-scrollbar-thumb {
    background-color: #FC5E24; /*  background color of the scrollbar thumb */
    border-radius: 5px; /* rounding the corners of the scrollbar thumb */
}

.general_vn_site_sc::-webkit-scrollbar-thumb:hover {
    background-color: #FC5E24; /*  background color of the scrollbar thumb when hovered */
}

.general_vn_site_sc_row {
    width: 100%;
    padding: 2px; 
    margin: 0;
}

.entity .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.hierarchy-border {
    border-right: 1px solid #DEE8F4;
    height: 22em;
}

.sites_action_btns button{
    border: none;
    background-color: transparent;
    font-size: 16px;
    color: #333333;
    font-style: normal;
    font-weight: 600;
}

.sites_action_btns button:disabled{
    opacity: 0.5;
}

.user .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.banks .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}
  
.business_classification .rs_h_controller.rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.display_vendor_categories .chips-list-page {
    line-height: 25px;
}

.chip {
    display: inline-block;
    padding: 0 25px;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    font-weight: inherit;
    border-radius: 25px;
    background-color: #ffffff;
    cursor: pointer;
    transition: 0.2s;
  }
  .chip:hover {
    background-color: #ffffff;
    color: white;
    transition: 0.2s;
  }
  
 .imgSites {
    user-select: none;
   width: 20px;
   height: 20px;
   border-radius: 50px;
   background-color: #ececec;
   cursor: pointer;
  }

 .chips-list-page button{
    border: none;
    text-align: center;
    border-radius: 40%;
    color: #ffffff; 
    font-size: 18px; 
    background: transparent;
 }
  
 .chips-container-vn-category {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .chip-vn-category {
    background-color: #eee;
    padding: 4px 8px;
    border-radius: 16px;
    cursor: pointer;
  }
  
  .selected {
    background-color: #007bff;
    color: #fff;
  }

/* Vendor Screen External Css*/
 

/* .vendor-screen-external {
  background-color: #f7f9fb !important;
} */

/* .vendor-screen-external .lynkaz_tab_contents_content {
    background-color: #F7F9FB;
} */
  
.vendor-screen-external .m_sidebar_items {
  background-color: #fff;
}
 
.external_vendor_profile .lists_contant {
    background: #ffffff;
}
 
.vendor-screen-external .ag-header {
  background-color: #535e86 !important;
}

.vendor-screen-external .ag-header-cell .ag-header-cell-text {
  color: #fff !important;
}