
.main_header {
	display: block;
	border-bottom: 1px solid #DCDCDC;
	padding: 20px 0;
	margin: 0;
}
.main_header .mh_inner{
   padding:0 30px;
}
.main_header .left_section {
	display: flex;
	align-items: center;
   gap: 15px;
}
.main_header .left_section .sidebar_toggle {
	height: 16px;
	cursor: pointer;
	display: block;
	margin: 0;
	padding: 0;
}
.main_header .left_section .language-select{}
.main_header .left_section .language-select .btn {
	padding: 0;
	margin: 0;
	width: 30px;
}
.main_header .left_section .language-select .btn svg {
   height: 20px;

}

.main_header .right_section {
	gap: 14px;
	align-content: center;
	justify-items: center;
	align-items: center;
}
.main_header .search_box_icon{}
.main_header .search_box_icon .icon{}
.main_header .search_box_icon .icon img {
	height: 28px;
}
.main_header .note{}
.main_header .note img {
	height: 25px;
}
.main_header .notification_controller{
   position: relative;
}
.main_header .notification_controller img {
	height: 25px;
   cursor: pointer;
}
.main_header .notification_controller span {
	display: block;
	width: 10px;
	height: 10px;
	background: #FC5E24;
	position: absolute;
	top: 3px;
	right: 0px;
	border-radius: 50%;
	border: 2px solid #fff;
}
.main_header .user_widget_menu {
	position: relative;
	padding-left: 15px;
}
.main_header .user_widget_menu:after {
	position: absolute;
	content: "";
	height: 30px;
	background: #DCDCDC;
	width: 1px;
	left: 0;
	top: 0;
	margin: auto;
	bottom: 0;
}
.main_header .user_widget_menu > .d-flex {
	justify-items: center;
	align-items: center;
   cursor: pointer;
}
.main_header .user_widget_menu .u_name {
	font-size: 13px;
	font-weight: 700;
	color: #252733;
}
.main_header .user_widget_menu .u_p_wraper {
	width: 40px;
	height: 40px;
	background: #fff;
	border: 1px solid #fff;
	border-radius: 50%;
	box-sizing: border-box;
	box-shadow: 0 0 0 2px #DFE0EB;
	position: relative;
	margin: 0 10px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
.main_header .user_widget_menu  .u_p_wraper .u_pp {

}
.main_header .user_widget_menu .arrow {
	margin-left: 5px;
	height: 8px;
   transition: all .5s;
}
.main_header .user_widget_menu.open .arrow {
	transform: rotate(180deg);
}
.main_header .user_widget_menu .dropdownwidget {
	position: absolute;
	top: 100%;
	z-index: 1000000000;
	right: 0;
	background: #fff;
	border: 1px solid #DCDCDC;
	padding: 15px;
	box-shadow: 0 3px 5px rgba(0,0,0,.2);
	min-width: 160px;
   display: none;
}
.main_header .user_widget_menu.open .dropdownwidget {
   display: block;
}
.main_header .user_widget_menu .dropdownwidget ul {
	margin: 0 0 20px 0;
	padding: 0;
	display: block;
	list-style: none;
}
.main_header .user_widget_menu .dropdownwidget ul li{}
.main_header .user_widget_menu .dropdownwidget ul li a {
	display: block;
	color: #333;
	text-decoration: none;
	font-size: 14px;
	padding: 3px 0;
}
.sidebar_section .sidebar_menu {
	display: block;
	margin: 0;
	padding: 30px 0;
	list-style: none;
	max-height: calc(100vh - 70px);
	overflow: auto;
	/* -ms-overflow-style: none; */
	/* scrollbar-width: none; */
	transition: 0.8s;
}

.sidebar_section .sidebar_menu::-webkit-scrollbar {
		/* display: none; */
		width: 0;
		background-color: #252733;
		transition: 0.6s;
}
.sidebar_section .sidebar_menu > li {
	display: block;
	margin: 0;
	padding: 0;
}
.sidebar_section .sidebar_menu > li > a {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	color: #D9D9D9;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding:10px 20px;
	gap: 10px;
	transition: all .5s;
}
.sidebar_section .sidebar_menu > li:hover > a {
	background-color: rgba(255, 255, 255, .1);
	color: #fff;
}
.sidebar_menu>li>a span{}
.sidebar_menu>li:hover>a span{}
.sidebar_menu > li > a img {
	height: 20px;
	margin: 0;
	padding: 0;
}
.sidebar_menu ul {
	margin: 20px;
	padding: 5px;
	display: none;
	background: rgba(0,0,0,0.2);
	list-style: disc;
	color: #fff;
}
.sidebar_menu li.active > ul {
	display: block;
}
.sidebar_menu ul li {
	margin-left: 20px;
}
.sidebar_menu ul li a {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color:  #D9D9D9;
	text-decoration: none;
	padding: 5px 4px 5px 0px;
	display: block;
	transition: all .5s;
}
.sidebar_menu ul li a:hover{
	color: rgb(255, 255, 255);
}