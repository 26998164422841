.fiscal-calendar-section {
    width: 400px !important;
    height: 388px !important;
    background: #FFFFFF;
box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
border-radius: 8px;
border: none;
padding: 22px;
}

.fiscal-calendar-heading {
    /* font-family: 'Titillium Web'; */
font-style: normal;
font-weight: 600;
font-size: 20px;
}

.fiscal-calendar-section label {
    /* font-family: 'Titillium Web'; */
font-style: normal;
font-weight: 400;
font-size: 16px;
color: #303030;
}
 
.fiscal-calendar-section input[type='text'] {
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    box-sizing: border-box;
    width: 368px;
height: 48px;
font-size: 20px;
        outline: none;
        width: 100%;
    }
 
    .fiscal-calendar-section textarea {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 4px;
        box-sizing: border-box;
        width: 368px;
        height: 120px;
    font-size: 20px;
            outline: none;
            width: 100%;
            max-height: 130px;
            min-height: 110px;
}

.fiscal-calendar-section {
    width: 400px;
    height: 388px;
    background: #FFFFFF;
box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
border-radius: 8px;
border: none;
}

.fiscal-calendar-heading {
    /* font-family: 'Titillium Web'; */
font-style: normal;
font-weight: 600;
font-size: 20px;
}

.fiscal-calendar-section label {
    /* font-family: 'Titillium Web'; */
font-style: normal;
font-weight: 400;
font-size: 16px;
color: #303030;
}
 
.fiscal-calendar-section input[type='text'] {
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    box-sizing: border-box;
    width: 368px;
height: 48px;
font-size: 16px;
        outline: none;
        width: 100%;
    }
 
    .fiscal-calendar-section textarea {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 4px;
        box-sizing: border-box;
        width: 368px;
        height: 120px;
    font-size: 16px;
            outline: none;
            width: 100%;
            max-height: 130px;
            min-height: 110px;
}
 

.fiscal-calendar-section .create-fiscal-calendar {
    background: #2D74E0;
    border-radius: 4px;
    width: 124px;
    height: 40px; 
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    border: none;
    text-align: center;
    color: #FFFFFF;
    margin-left: 14.4rem;
}
 
.end_date_fiscal_btn {
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    box-sizing: border-box;
    width: 368px;
    height: 48px;
    font-size: 16px;
    outline: none;
    width: 100%;
  }