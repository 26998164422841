.unit-currency { 
        /* font-family: 'Titillium Web'; */
        font-style: normal;
        font-weight: 600;
        font-size:18px; 
}

.grid-contain .rs_ag_grid_panel .ag_grid_footer .rs_grid_page_status { 
    display: none ;
}
 
.grid-contain .right .rs_h_controllers {
    display: none !important;
}

.grid-contain .rs_gh_search_box{
    display: none !important;
}

