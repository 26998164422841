.dashboard-request-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; 
    justify-content: space-between;
}

.dashboard-request-section .source-request-col {
    flex: 1 1 calc(25% - 20px);
    box-sizing: border-box;
    padding: 15px; 
    background-color: #fff;  
    border: 1px solid #ddd; 
}

.dashboard-request-section h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2C2828;
    margin-bottom: 10px; 
}
  
.request-row-badge {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    color: #FC5E24;
}

.request-row-badge.grouping_clr {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    color: #2D74E0;
}

.request-percentage {
    color: #32C572;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 23px;
}

.request-text {
    color: #738CAB;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 23px;
}

.rfp-request-percentage {
    color: #E94335;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 23px;
}

.rfp-request-text {
    color: #738CAB; 
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 23px;
} 