/* .jodit-toolbar-button__button {
    display: none;
} */

.edit-emails-section input[type='text'] {
    border-bottom: 1px solid #7E7E7E;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
    width: 100%;
}

.create-emails-section input[type='text'] {
    border-bottom: 1px solid #7E7E7E;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
    width: 100%;
}

/* .edit-emails-section label { */
    /* font-family: 'Titillium Web'; */
/* font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 24px;
display: flex;
align-items: center;
color: #7E7E7E;
} */


/* .create-emails-section label { */
    /* font-family: 'Titillium Web'; */
/* font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 24px;
display: flex;
align-items: center;
color: #7E7E7E;
} */