
.fiscal_calendar_heading { 
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #333333;  
}

.fiscal-year .toggle-wrapper > button , 
.fiscal-year-calendar button{
    border: 1px solid #DCDCDC;
    padding: 0.5rem;
    width: 100%;
    text-decoration: none;
    border-radius: 4px;
    transition: 0.2s; 
    color: #303030;
    background-color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;  
}

.fiscal-year-calendar button{ width: 185px; } 
.fiscal-year .toggle-wrapper > button { width: 160px; }
     
.fiscal-year-section { 
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    width: 400px;
    height: 559px;
    border: none;
}

    .fiscal-year-heading { 
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    }

    .fiscal-year-section label { 
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #303030;
    }

    .fiscal-year-section input[type='text'] {
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    box-sizing: border-box;
    width: 368px;
    height: 48px;
    font-size: 16px;
    outline: none;
    width: 100%;
    }

    .fiscal-year-section textarea {
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    box-sizing: border-box;
    width: 368px;
    height: 120px;
    font-size: 16px;
    outline: none;
    width: 100%;
    max-height: 130px;
    min-height: 110px;
    }

    .fiscal-year-section .create-fiscal-calendar {
    background: #2D74E0;
    border-radius: 4px;
    width: 124px !important;
    height: 40px !important; 
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    border: none;
    text-align: center;
    color: #FFFFFF !important;
    margin-left: 14.4rem;
    }
     
  
    .fiscal-year-delete:hover {
        border: 1px solid #2D74E0;
        transition: 0.2s;
    }

    .dropdown{
        border: none;
        z-index: 6
    }

    .fiscal-year-calendar .dropdown-menu.show{
    margin-top: 1rem;
    }

    .fiscal-year .dropdown-menu.show {
    margin-top: 1rem;
    }
 
    .fiscal-year.dropdown { z-index: 6 } 

    .fiscal-year-delete {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 9px;
    gap: 10px;
    width: 42px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    border-radius: 4px;
    flex: none;
    order: 2;
    flex-grow: 0;
    }

    .fiscal_year_sidebar {
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    border-radius: 4px; 
    height: 780px;
    max-height: auto; 
    margin-left: 1.2rem;
    } 
    .fiscal_year_content { 
    max-height: auto;
    }  

    .fiscal_year_content .grid {
    border: 1px solid #DCDCDC;
    border-radius: 4px;
    } 

    .fiscal_year_content .info { 
    background: #F8F9FA;
    border-radius: 4px;
    height: 13rem;
    } 

    .info-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 16px; 
    color: #333333;
    }

    .info-calender-label { 
    font-style: normal;
    font-weight: 400;
    font-size: 14px; 
    color: #565656; 
    }

    .info-start-date-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px; 
    color: #565656; 
    }

    .info-end-date-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px; 
    color: #565656; 
    }

    .info-calender {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    }

    .info-calender-description-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px; 
    color: #565656; 
    }

    .info-description {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    }
    .info-start-date {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    }

    .info-end-date {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    }

