.inner-page-section {   
  background-color: #F7F9FB; 
}
.inner-page-section h1{
    /* font-family: 'Titillium Web'; */
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 37px;   
    color: #000000;
}

.inner-page-container { 
  padding: 1rem 1.5rem 1rem 1.5rem;
  background-color: #ffffff;
}