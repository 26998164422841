.command-center-text {
/* font-family: 'Titillium Web'; */
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 27px; 
color: #333333;
}
.command-center-icon {
width: 20px;
height: 20px;
}
.file-download-icon {
width: 40px;
height: 40px;
}