body {
	

}
.login_register_page {
	background-color: #FEF4F5;
	min-height: 100vh;
	margin: 0;
	padding: 0;
	display: flex;
}
.login_register_sidebar{
	background-color: #FF6A00;
	width: 50%;
	overflow: hidden;
	min-height: 100%;
	padding:40px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.login_register_sidebar .logreg_sidebar_contents{
	display: block;
	width: 700px;
	max-width: 100%;
	margin: auto;
}
.login_register_sidebar .logreg_sidebar_contents .logreg_title{
	margin: 0 0 20px 0;
	padding: 0;
	display: block;
	font-size: 45px;
	color: #fff;
	text-align: center;
	font-weight: 700;
}
.login_register_sidebar .logreg_sidebar_contents .logreg_banner{
	margin: 0;
	padding:0;
	display: block;
}
.login_register_sidebar .logreg_sidebar_contents .logreg_banner img{
	width: auto;
	max-width: 100%;
	margin: auto;
	display: block;
}
.login_register_sidebar .logreg_footer_menu{
	padding: 0;
	display: block;
	overflow: hidden;
	list-style: none;
	width: 700px;
	max-width: 100%;
	margin: auto auto 0 auto;
}
.login_register_sidebar .logreg_footer_menu li{
	display: block;
	float: left;
	margin: 0;
	padding: 0;
}
.login_register_sidebar .logreg_footer_menu li .circle{
	display: block;
	background: #fff;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	margin: 9px auto;
}
.login_register_sidebar .logreg_footer_menu li a{
	display: block;
	color: #fff;
	text-decoration: none;
	font-weight: 700;
	font-size: 14px;
	padding: 0 30px;
	transition: all .5s;
}
.login_register_sidebar .logreg_footer_menu li a:hover{
	opacity: .8;
}
.loginreg_contents {
	width: 50%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
}
.login_regiser_box {
	padding: 30px 0 30px 0;
}
.login_regiser_box  .login_logo{
	display: block;
	text-align: center;
	margin: 0 0 70px 0;
	padding: 0;
}
.login_regiser_box  .login_logo img{
	width: 280px;
	margin: 0 auto;
	max-width: 100%;
	display: block;
	height: auto;
}
.lr_box_inner {
	display: block;
	width: 440px;
	max-width: 100%;
	margin: 0 auto;
	box-sizing: border-box;
}
.lr_box_inner .log_reg_th {
	display: flex;
	width: 100%;
	justify-content: left;
	gap: 20px;
}
.lr_box_inner .log_reg_th a {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	text-align: center;
	text-decoration: none;
	text-transform: none;
	color: #181818;
	display: block;
	cursor: pointer;
	border-bottom: 2px solid transparent;
	padding: 0 0 2px 0;
}
.lr_box_inner .log_reg_th a.active{
	border-bottom-color: #FC5E24;
}
.login_form_group {
	padding: 40px;
	margin: 20px 0 0 0;
	display: block;
	box-sizing: border-box;
	background: #fff;
	box-shadow: 0 0 10px rgba(0,0,0,.1);
	border-radius: 20px;
}
.login_form_group .form-group{
	margin: 0;
}
.login_form_group .form-group+.form-group{
    margin-top: 15px;
}
.login_form_group .form-group .form-control {
	display: block;
	height: 45px;
	font-size: 15px;
	font-weight: 400;
	color: #949494;
	outline: none !important;
	box-shadow: none !important;
	border: 1px solid #ABABAB;
	padding-left: 15px;
	border-radius: 6px;
	background: #fff;
}
.login_form_group .form-group.invalid .form-control{
	border-color: #D73535;
}
.login_form_group .form-group .form-control:focus,
.login_form_group .form-group.invalid .form-control:focus{
	border-color: #2D74E0;
}
.login_form_group .form-group label{
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	display: block;
	color: #090914;
	margin: 0 0 5px 0;
}
.login_form_group .form-group .form-text {}
.login_form_group .form-group .form-text.danger{}
.login_form_group .form-group  input::placeholder {
    color: #949494;
}
.lr_box_inner .btn {
	height: 45px;
	border: 0.5px solid #7D879E;
	border-radius: 2px;
	/* font-family: 'Titillium Web'; */
	font-size: 12px;
	color: #58585A;
	padding: 0 20px;
    box-shadow:  none !important;
    outline:  none !important;
    transition: all .5s;
}
.lr_box_inner .btn+.btn{
    margin-top: 10px;
}
.lr_box_inner .btn .spinner-border{
	position: relative;
	top:2px;
	margin-left: 5px;
}
.lr_box_inner .btn.sb_btn {
	font-weight: 600;
	font-size: 14px;
	text-shadow: none !important;
	margin-top: 40px;
	background: #FC5E24;
	border-color: #FC5E24;
	color: #fff;
	text-align: center;
	height: 45px;
	border-radius: 6px;
	opacity: 1;
	transition: all .5s;
}
.lr_box_inner .btn.sb_btn:hover{
	opacity: .8;
}
.lr_box_inner .btn.sb_btn img{
	margin-left: 5px;
	display: inline-block;
	height: 15px;
}
.lr_box_inner .btn_block{
    width: 100%;
}

.lr_box_inner .btn_blue {
	color: #fff;
	background: #2D74E0;
	border-color: #2D74E0;
}
.lr_box_inner .btn:hover,
.lr_box_inner .btn_blue:hover{
    background-color: #FC5E24;
    border-color: #FC5E24;
    color: #fff;
}
.login_regiser_box .lf_title_subtitle {
	margin: 0 0 21px 0;
	padding: 0;
}
.login_regiser_box .lf_title_subtitle h2 {
	font-style: normal;
	font-weight: 600;
	font-size: 34px;
	line-height: 33px;
	color: #090914;
	margin: 0;
	padding: 0;
	display: block;
}
.login_regiser_box .lf_title_subtitle p {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 23px;
	color: #52525B;
	margin: 10px 0 0 0;
}
.btn_login_with_google{}
.btn_login_with_google img {
	height: 25px;
	margin-right: 5px;
	display: inline-block;
}
.login_form_group .pwr_link {
	margin: 11px 0 0 0;
	padding: 0;
	display: block;
	text-align: right;
}
.login_form_group .pwr_link a{
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #FF6A00;
	text-decoration: none;
	display: inline-block;
	transition: all .5s;
	text-align: right;
	transition: all .5s;
}
.login_form_group .pwr_link a:hover{
	opacity: .8;
}
.registration_page .lr_accept_tc_checkbox{
	display: block;
	overflow: hidden;
	margin-top: 15px;
	font-size: 14px;
	font-weight: 400;
	color: #090914;
}
.register_page .lr_accept_tc_checkbox .rs_input_checkbox{
	display: block;
	margin-right: 5px;
	float: left;
}
.register_page .lr_accept_tc_checkbox a{
	text-decoration: none;
	color: #FC5E24;
	transition: all .5s;
}
.register_page .lr_accept_tc_checkbox a:hover{
	opacity: .8;
}
.register_page .lr_box_inner .btn.sb_btn{
	margin-top: 20px;
}
.login_register_page .select2-container--default .select2-selection--single {
	background-color: transparent !important;
	border: 1px solid #AFAFAF;
	height: 45px;
	outline: none;
	border-radius: 6px;
	padding: 6px 15px;
	font-size: 15px;
  }
  .login_register_page .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 26px;
	position: absolute;
	top: 9px;
	right: 16px;
	width: 20px;
  }