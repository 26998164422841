 
.new-organsation-unit {
    height: 85vh; 
}

.edit-organsation-unit { 
    height: 85vh; 
}
  
.new-organsation-unit .form-group select.form-control {
    font-size: 12px !important;
}

.edit-organsation-unit .form-group select.form-control {
    font-size: 12px !important;
}
