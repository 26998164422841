@import './assets/css/main.css'; /* Using a url */ 
@import './assets/css/section-main.css'; /* Using a url */ 

.grid-column-styles {

    font-style: normal;
    font-weight: 300 !important;
    font-size: 13px;
    line-height: 20px; 
    color: #303030;
    height: 2.2rem;
    max-height: 2.2rem;
    min-height: 2rem;
} 

.pending_actions_collapse .rs_gh_search_box, 
.pr_module_grid .rs_gh_search_box, 
.rfx_module_grid .rs_gh_search_box,
.common_module_grid .rs_gh_search_box {
  display: none;
}
 

.rs_active_label_actions {
  text-align: center;
  display: inline-block;
  padding: 4px 14px;
  color: #3B3E45;
  border-radius: 4px;
  margin: 0 auto;
  height: auto;
  line-height: normal;
}

.currency_action_btns {
  display: flex;
  justify-content: start;
  gap: 12px;
  align-items: center;
}

.pending_actions_collapse .rs_ag_grid_panel .rs_ag_header, 
.pr_module_grid .rs_ag_grid_panel .rs_ag_header, 
.rfx_module_grid .rs_ag_grid_panel .rs_ag_header,
.common_module_grid .rs_ag_grid_panel .rs_ag_header{
  border: 0;
  padding: 0;
}

.grid_scoring_section .rs_h_controller {
   background: transparent;
   border: none;
}

.product_image_section_item_master {
  background: #F9F9F9;
  height: 150px; 
}

.hide_sidebar .image_section_employee {
  display: none;
} 

.hide_sidebar .product_image_section_item_master,
.hide_sidebar .order_summary_wrapper_purchase_requisition {
  display: none;
} 

.bidding_count_screen .bidding_count_grid .rs_ag_header, .bidding_count_screen_edit .bidding_count_grid .rs_ag_header { display: none !important; }

.bidding_count_screen .bidding_count_grid .rs_ag_grid_panel .ag_grid_wrapper, .bidding_count_screen_edit .bidding_count_grid .rs_ag_grid_panel .ag_grid_wrapper {padding: 0 !important}

.rfq_awarding_process_policy .awarding_grid .rs_ag_header, .bidding_count_screen_edit .awarding_grid .rs_ag_header { display: none !important; }

.rfq_awarding_process_policy .awarding_grid .rs_ag_grid_panel .ag_grid_wrapper,  .bidding_count_screen_edit .awarding_grid .rs_ag_grid_panel .ag_grid_wrapper {padding: 0 !important}

.rfq_awarding_process_policy .line_manager_cn .line_manager_chld h3{ 
  font-weight: 600;
  width: 165px;
  font-size: 16px; 
  border-bottom: 1px solid #2D74E0;
  padding-bottom: 1px;
  color: #000000;
}
  
.rfq_awarding_process_policy .line_manager_cn .line_manager_chld button {
  background: transparent;
  border: none;
}

.rfq_awarding_process_policy .new_line_creation button{
  background: transparent;
  border: none;
  color: #2D74E0;
}
  
.notes_widgets::-webkit-scrollbar {
  width: 8px; 
}

.notes_widgets::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;  
  border-radius: 10px;  
  cursor: pointer;
}

.notes_widgets::-webkit-scrollbar-thumb:hover {
  background-color: #D9D9D9;
}

.notes_widgets::-webkit-scrollbar-track {
  background-color: #ffffff;  
}
 
.no_padding_class .rs_master_canvas { padding: 0 !important; };
   
.action-btns-scoring-templates-wrapper .rfq-header-dropdown {
  z-index: 3;
}

.vendor_selection_text {
  font-size: 16px;
  color: #2D74E0;
  font-weight: 700;
}

.selected_point {
  color: #FC5E24;
}
  
.vl {
  border-left: 1px solid green;
  height: 0px;
}

.treeview_vendor ul, li { list-style: none; margin: 0; padding: 0; }
.treeview_vendor ul {  padding: 0;
  user-select: none;}

.treeview_vendor ul ul li { padding-left: 1em;
  border: 1px dashed #868686;
  border-width: 0 0 1px 1px;  
  padding-top: 0.9rem;
  cursor: pointer;
  
  user-select: none;
}

.treeview_vendor li.treeview_vendor_heading { 
  border-bottom: 0px; 
border-left: 0px;
}

/* Made the disabled color of react-datepicker, dropdown aligned with the disabled color of the input field */
.react-datepicker-wrapper input:disabled { 
  background-color: rgba(239, 239, 239, 0.3) !important;
  color:rgb(84,84,84) !important;
  border-bottom: 1px solid #7d7d7d;
}

.form-group.disabled-dropdown-class .select2-container .select2-selection--single .select2-selection__rendered {
  color: rgb(84,84,84); 
  background-color: rgba(239, 239, 239, 0.3);
}

.treeview_vendor li.treeview_vendor_heading::before {
  
border-left: 0px;
} 

.treeview_vendor li p  { 
  margin: 0; 
  position: relative;
  top: 0.5em; 
}
 
 .treeitem{
  color: #212529;
 }
.treeview_sub_item_active{
  color:#FC5E24 ;
}
/* .treeview_vendor_heading h3::selection {
  color: #32C572;
} */
.treeview_vendor_heading h3 {
  margin: 0;
  margin-left: 0.8rem; 
  top: 0.5em;
  font-size: 16px; 
}
.treeview_vendor r {
  margin-left: 2em;
}
.treeview_vendor li ul { 
  border-top: 1px dotted rgb(255, 255, 255); 
  margin-left: -1em;     
  padding-left: 2em; 
}
.treeview_vendor_heading h3::before {
  content: '• ';
  color:#FC5E24; 
}

/* Actions History Section CSS */
   
.action_history {
  width: auto;
  height: 300px;
  padding-top: 0.7rem;
  padding-bottom: 0.1rem;
  padding-right: 0.4rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.action_history .row.action_history_info_wrapper {
  border-bottom: 1px dashed #DCDCDC;
  margin-bottom: 1.5rem;
}

.action_history .row.action_history_info_wrapper:last-child { border-bottom: none }

.hide_sidebar .action_history {
  height: 200px;
}

.action_history::-webkit-scrollbar {
  width: 6px;
}

.action_history::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.action_history::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;  
  border-radius: 10px;  
  cursor: pointer;
}

.action_history::-webkit-scrollbar-thumb:hover {
  background-color: #D9D9D9;
}

.request_btn {
  background-color: #2D74E0;
  border: none; 
  color: #fff;
  padding: 8px 18px 8px 8px;
  border-radius: 4px;
  font-weight: 600;
}

.modules_screen_lynkaz .rs_master_canvas { padding: 0; }

.tab_item_badge {
  height: auto;
  display: inline-block;
  color: #fff;
  text-align: center;
  padding: 0.2rem 0.5rem 0.1rem 0.5rem;
  border-radius: 50%;
  font-size: 14px;
  background-color: #7D879E;
  margin-left: 0.4rem;
}

.pr_module_grid, .rfx_module_grid, .common_module_grid {
  padding: 1.1rem;
}

.lynkaz_tab .lynkaz_tab_nav .lynkaz_tab_nav_item.active .tab_item_badge  {
  background-color: #FC5E24;
}

.action_history .dropdown-menu-task {
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.25);
  padding-top: 1rem;
  padding-bottom: 0.4rem;
  padding-right: 1rem;
  padding-left: 1rem;
  z-index: 999;
  position: absolute;
  left: 6.5%;
  width: 290px;
}

.vendor_terms_conditions_wrapper {
  height: 500px;
  overflow-y: scroll;
}

.checkbox_terms_conditions_wrapper {
  margin-top: 1rem; 
  border: 0;
  border-top: 1px solid #bdbdbd;
  border-bottom: 0; 
  border-left: 0;
  border-right: 0;
}

.action_history .dropdown-menu-kpi h5 span {
  color: #2D74E0;
}

.action_history .dropdown-menu-kpi h5 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}

.action_history .dropdown-menu-kpi { 
  display: inline-block;
  background-color: #FAFAFA;
  box-shadow: 0px 4px 20px 2px rgba(193, 193, 193, 0.5);
  z-index:  999;
  padding-top: 1rem;
  padding-bottom: 0.4rem;
  padding-right: 1rem;
  padding-left: 1rem;
  position: absolute; 
  left: -7.5%; 
  width: 350px;
  top: 55px;
}

.descriptionTextarea {
  width: 100%;
  min-height: 100px;
  outline: none;
  max-height: 120px;
  border-radius: 5px;
  border: 1px solid #E6E6E6;
}

.descriptionTextarea:focus {
  outline: none;
}

.barcode_controller{
  border: none;
  background-color: transparent;
}

.action-history-comp .dropdown-menu-kpi {
  left: -9.5%;
}

.action-history-comp .action_history .action_status .current_state_wrapper {
  width: 40%
}

.action-history-comp .action_history .action_status .excel_export_wrapper {
  width: 10%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.action-history-comp .action_history .action_status .current_assignees_history_wrapper {
  display: flex;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.action_history .kpi-flag { cursor: pointer; display: flex; align-items: center; }

.action_history .dropdown-menu-kpi::after {
  content: "";
  position: absolute;
  top: -16px;
  left: 60%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #ffffff transparent;  
}

.workflow-action-history-module .action_history{ 
  min-height: 75vh;
  max-height: 100%;
  border: 1px solid #DCDCDC;
  border-radius: 8px;
  padding-left: 0.7rem;
  padding-right: 0;
  padding-top: 1.5rem;
}

.workflow-action-history-module .workflow_action_history_wrapper {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
}

.workflow-action-history-module .workflow_action_history_wrapper .action_history_instance_dropdown_wrapper {
  width: 92%;
}

.workflow-action-history-module .workflow_action_history_wrapper .action_history_excel_records_export {
  width: 8%;
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
}

.workflow-action-history-module .action_history .user-logo-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 13%;
}

.workflow-action-history-module .action_history .context-col {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin: 0;
  padding: 0;
  width: 87%;
}

.workflow-action-history-module .action_history .context-col .comms-date-row {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.workflow-action-history-module .action_history .context-col .user-current-stage {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start; 
  padding: 0;
  margin: 0;
  width: 94%;
}

.workflow-action-history-module .action_history .context-col .local-date-kpi-col {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start; 
  padding: 0;
  margin: 0;
  width: 30%;
}

.workflow-action-history-module .action_history .context-col .bullet-list-button {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: start;
  padding: 0;
  margin: 0;
  width: 10%;
}

.workflow-action-history-module .action_history .user-comment-row {
  display: flex;
  flex-direction: row;
  width: 90%;
}

.workflow-action-history-module .action_history .action-history-user-comment-inputarea {
  display: flex;
  flex-direction: column;
  width: 100%;
}
 
.action_history .user-logo-container {
  position: relative; 
  width: 45px;
  height: 45px;
  border-radius: 50%; 
}
  
/* Common Terms & Conditions / Compliance Templates Component CSS */

.add_templates_popup .top_head_line_wrapper,
.common_compliance_popup .top_head_line_wrapper {
  margin-bottom: 1rem;
}
  
.add_templates_popup .top_head_line_wrapper .btn_blue_templates_saving,
.common_compliance_popup .btn_blue_templates_saving{
  background-color: #2D74E0;
  color: #fff;
  border: none;
}

.add_templates_popup .terms_condition_common_heading {
  color: #303030;
  font-size: 20px;
  font-weight: 600;
}

.common_compliance_popup .compliance_common_heading {
  font-weight: 600;
  font-size: 20px;
  color: #303030;
}

.common_compliance_popup .compliance_common_heading_black {
  color: #303030;
  font-size: 20px;
  font-weight: 600;
}

.add_templates_popup .templates_next_btn {
  margin-top: 1rem;
}

#rfq_vendor_reply_graph .ag-group-child-count {
  display: none;
}

.setup_attachments_download_btn_styles {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #007BFF;
  cursor: pointer;
}

.setup_attachments_download_btn_font_icon_styles {
  width: 20px; 
  height: 20px; 
  margin-right: 8px;
}

/* common_scrollbar_styles, if apply this class, it will implement its own scrolling CSS */

.common_scrollbar_styles > div {
  position: relative;
}

.common_scrollbar_styles::-webkit-scrollbar,
.rs_tree_view_section::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  background-color: transparent !important; 
}

.common_scrollbar_styles:hover::-webkit-scrollbar,
.rs_tree_view_section:hover::-webkit-scrollbar {
  width: 8px !important; 
  height: 8px !important;
}

.common_scrollbar_styles:hover::-webkit-scrollbar-thumb,
.rs_tree_view_section:hover::-webkit-scrollbar-thumb {
  background-color: rgba(51, 49, 49, 0.295) !important;
  border-radius: 10px !important; 
  cursor: pointer;
}

.detail_view_headings_wrapper .collapse_for_texteditor .jodit-workplace,
.system-params-terms-conditions .collapse_for_texteditor .jodit-workplace {
  height: 400px !important;
  width: 100% !important;
}

.detail_view_headings_wrapper .collapse_for_texteditor .rtl_jodit_editor {
  direction: rtl !important;
}

.detail_view_headings_wrapper .jodit-status-bar .jodit-status-bar__item.jodit-status-bar__item-right {
  display: none !important;
}

.terms_html_content_display .template_headings_for_content {
  text-align: start;
  margin-bottom: 1rem;
}

.terms_html_content_display .template_headings_for_content h2 {
  font-weight: 600;
  font-size: 20px;
}

.terms_html_content_display .template_headings_for_content h2 span {
  color: #2D74E0;
}

.asterik_sign {
  color: red;
}

.terms_conditions_templates .templates_action_btns > button,
.terms_conditions_templates .templates_action_btns div > button,
.compliance_templates .templates_action_btns .common-grid-responsive-btns > button {
  background: transparent;
  border: none;
}

.common_detail_view_headings .terms_html_content_display .content_wrapper_html {
  height: 800px;
  overflow: auto;
}
 
.compliance_action_excel_btn {
  position: relative;
  width: 90px;
  height: 36px;
  overflow: hidden; 
  border-radius: 0;
  text-align: center;
  cursor: pointer;
  display: inline-block;  
}

.import_sample_template {
  position: relative; 
  margin-top: 5px;
  overflow: hidden;
  border-radius: 0;
  text-align: center;
  cursor: pointer;
  display: block;
}
.ag-grid-parent .ag-grid-btns-wrapper .select_role_btn {
  background-color: transparent;
  border: 1px solid #333333;
  font-size: 16px;
  color: #333333;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}
.import_sample_template button {
  border: none;
  color: #333333;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  padding: 0;
}

.terms-conditions-template-wrapper {
  display: flex;
  margin-top: 24px;
  gap: 25px; 
}

.terms-conditions-templates-left-sidebar {
  max-width: 480px;
  width: 100%;
  border: 1px solid #dee8f4;
  background-color: #fff; 
}
 
.collase_with_rtl {
  direction: rtl;
}

/* This class is for popup components having 1200px as default width being sended into the component */
.common_responsive_class_popup_component > .rs_popup_section .popup_contents {
  width: 1200px;
}

@media (max-width: 1320px) {
  .common_responsive_class_popup_component > .rs_popup_section .popup_contents {
    width: 1100px; 
  }
}
 
@media (max-width: 1224px) {
  .common_responsive_class_popup_component > .rs_popup_section .popup_contents {
    width: 950px; 
  }
}

@media (max-width: 1050px) {
  .common_responsive_class_popup_component > .rs_popup_section .popup_contents {
    width: 900px; 
  }
}

@media (max-width: 990px) {
  .common_responsive_class_popup_component > .rs_popup_section .popup_contents {
    width: 830px; 
  }
}

@media screen and (max-width: 900px) {
  .terms-conditions-templates-left-sidebar {
    max-width: 100%; 
  } 
  .terms-conditions-template-wrapper {
    flex-direction: column; 
  } 
}
  
.compliance_action_excel_btn input[type="file"] {
  position: absolute; 
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  display: block; 
}

.compliance_action_excel_btn::after {
  content: 'Import';
  display: inline-block;
  line-height: 35px;
  color: #333333;
  margin-left: 7px;
  font-size: 16px;
  font-weight: 600;
  vertical-align: middle;  
}

.vendor_action_excel_btn::after {
  content: 'Import catalogue' !important; 
}

.compliance_action_excel_btn input[type="file"]::after {
  content: none;
}

/* Ag-Grid Advance Filter & AG Grid Loading Overlay CSS */

.ag-overlay-loading-center {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fffffff0;
}

.ag-overlay-loading-template {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 5px; 
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: 4px solid #333;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.ag-grid-advance-filter {
  position: relative;
  display: inline-block;
}

.ag-grid-advance-filter .dropdown-toggle{
  width: 167px;
  height: 42px;
  line-height: 3px;
  border-radius: 4px;
  background: #fff; 
  padding: 9px 16px;
  font-size: 16px;
  cursor: pointer;
}

.ag-grid-advance-filter .form-group .select2-container--default .select2-selection--single {
  border: 1px solid #F1F1F1;
  border-radius: 4px; 
  width: 100%;  
  height: 36px;
}

.ag-grid-advance-filter .form-group {
  margin-bottom: 24px;
}

.ag-grid-advance-filter .form-group label {
  font-weight: 400;
}

.ag-grid-advance-filter .dropdown-toggle.active-cls{ 
  border: 1px solid #7E7E7E;
  color: #7E7E7E; 
}

.ag-grid-advance-filter .dropdown-toggle.dropdown-active-cls{  
  border: 1px solid #FC5E24;
  color: #FC5E24; 
}

.ag-grid-advance-filter .form-group .select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 4px;
  margin-top: 3px;
} 

.ag-grid-advance-filter .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 6px;
  right: 6px;
}

.ag-grid-advance-filter .rs_gh_search_box input {
  border: 1px solid #F1F1F1 !important;
  width: 100% !important;
}

.ag-grid-advance-filter .rs_gh_search_box input {
	background-image: url('../public/images/icons/new-search-icon.svg') !important;
}
 
.ag-grid-advance-filter .search-dropdown-menu {
  background-color: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.2);  
  border-radius: 4px;
  position: absolute;
  padding: 15px 20px; 
  width: 100%;
  z-index: 100;
  top: 50px;
} 

.ag-grid-advance-filter .search-key-btn{
  display: flex;
  justify-content: end;
  align-items: center;  
}

.ag-grid-advance-filter .search-key-btn button{  
  width: 124px;
  height: 40px;
  border-radius: 4px;
}

.ag-grid-advance-filter .search-key-btn .search-cls {
  border: none;  
  background-color: #2D74E0;
  color: #fff;
}

.ag-grid-advance-filter .search-key-btn .default-cls {
  border: 1px solid black;  
  background-color: #ffffff;
  color: #000000;
}

/* Compliances Consolidated View - STYLES */

.compliances_consolidated_header {
  position: sticky;
  top: 0;
  z-index: 8;
  display: grid;
  grid-template-columns: 270px auto;
  align-items: center; 
  height: auto;   
  user-select: none;   
  color: #fff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding-right: 10px;
  padding-left: 10px;
  background-color:#273A59;
  cursor: pointer;
}

.compliances_consolidated_header .scrollable_column::-webkit-scrollbar,
.compliances_consolidated_header_cons .scrollable_column::-webkit-scrollbar{
  height: 3px;
  cursor: pointer;
}

.compliances_consolidated_header .scrollable_column::-webkit-scrollbar-track,
.compliances_consolidated_header_cons .scrollable_column::-webkit-scrollbar-track{
  background-color: #273A59;
  cursor: pointer;
}

.compliances_consolidated_header .scrollable_column::-webkit-scrollbar-thumb,
.compliances_consolidated_header_cons .scrollable_column::-webkit-scrollbar-thumb{
  background-color: #DEE8F4;
  border-radius: 10px;
  cursor: pointer;
}

.compliances_consolidated_header .fixed_column {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: row;
  justify-content: start;
  gap: 15px;
}

.compliances_consolidated_header .fixed_column p{
  font-weight: 600; 
  font-size: 16px;
  margin: 0;
  padding: 0;
}
 
.compliances_consolidated_header .scrollable_column {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  overflow-x: auto;
  font-weight: 500; 
  font-size: 16px;
  white-space: nowrap; 
}
 
.compliances_consolidated_header .inner_vendor_col {
  min-width: 240px;
  height: 30px;
  display: inline-flex; 
  justify-content: center;
  align-items: center;
}

/* dropdown styles */
.compliances_header_dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.compliances_header_dropdown .dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.375rem 0.75rem;  
  font-weight: 600;
  font-size: 16px; 
  background-clip: padding-box;  
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.compliances_header_dropdown .dropdown-toggle:focus {
  outline: none; 
}

.compliances_header_dropdown .dropdown-menu {
  position: absolute;
  top: 50px;
  left: -2px;
  z-index: 1005;
  display: block;
  float: left;
  min-width: 240px;
  padding: 1rem 0;
  margin: 0.125rem 0 5rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  /* border: 2px solid #DCDCDC; */
  border-radius: 4px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
}

.compliances_header_dropdown .dropdown-menu button:nth-child(even) {
  position: relative;
}

.compliances_header_dropdown .dropdown-menu button:nth-child(even)::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0.5rem; 
  width: calc(100% - 1rem); 
  border-bottom:1px solid rgba(134, 134, 134, 0.24);
}

.compliances_header_dropdown .dropdown-menu button:last-child::before {
  border-bottom: none;
}

.compliances_header_dropdown .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  font-size: 14px;
  color: #757575;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  transition: 0.1s ease-in-out;
}

.compliances_header_dropdown .dropdown-item:hover,
.compliances_header_dropdown .dropdown-item:focus {
  color: #fff;
  background-color: #007bff;
  transition: 0.1s ease-in-out;
}

.compliances_header_dropdown .arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.3rem;
  vertical-align: middle;
  border-top: 0.3rem solid;
  border-right: 0.3rem solid transparent;
  border-bottom: 0;
  border-left: 0.3rem solid transparent;
  transition: transform 0.1s ease-in-out;
}
 
.compliances_header_dropdown .up {
  transform: rotate(-135deg);
}

.compliances_header_dropdown .down {
  transform: rotate(45deg);
}

.compliances_header_dropdown .active-drp {
  color: #ffffff;
  background: transparent;
  border: 1px solid #FFFFFF; 
}

.compliances_header_dropdown .default-drp {
  color: #ffffff;
  background: transparent;
  border: 1px solid #FFFFFF; 
}

.compliances_sections_cn  {
  height: auto;
  overflow: auto; 
  position: relative; 
}
   
.compliances_sections_cn .container-fluid {
  max-height: 272px;  
  /* width: calc(100% - 544px); */ 
}

.compliances_sections_cn::-webkit-scrollbar {
  width: 3px;
  height: 5px; 
  cursor: pointer;
} 

.compliances_sections_cn::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.compliances_sections_cn::-webkit-scrollbar-track {
  background-color: #E6E6E6;
  cursor: pointer;
}

.compliances_sections_cn .complainces_row {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.compliances_sections_cn .row {
  display: flex;
  flex-wrap: nowrap;  
  width: 100%;  
}
 
.compliances_sections_cn .column,
.compliances_sections_cn .compliance_column {    
  border-bottom: 1px solid #DCDFE3;
  border-right: 1px solid #DCDFE3;
  padding-top: 10px;  
  padding-bottom: 10px;
  position: relative;
}
  
.compliances_sections_cn .column { 
  min-height: 58px; 
  left: 0px;
}

.compliances_sections_cn .compliance_column {
  min-height: 28px; 
  left: 0px;
  color: #2D74E0;
  size: 14px;
  font-weight: 600;
}

.compliances_sections_cn .row .section-content,
.compliances_sections_cn .complainces_row .compliance_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
}

.compliances_sections_cn .row .section-content {
  min-width: 240px; 
  max-width: 240px; 
  left: 12px;
}

.compliances_sections_cn .row .empty-div {   
  padding-top: 10px;  
  padding-bottom: 10px;
  position: relative;
  border: none;
}
 
.compliances_sections_cn .complainces_row .compliance_content { 
  min-width: 240px;
  padding-left: 10px;
  padding-right: 10px;
}

.compliances_sections_cn .row .section-head,
.compliances_sections_cn .complainces_row .fixed_compliance_col {
  min-width: 259px;
  max-width: 259px;
  flex: 0 0 auto; 
  position: sticky; 
  z-index: 99;
  background-color: #fff;
}

.compliances_sections_cn .row .section-head {
  padding: 7px 15px; 
  z-index: 7;
}

.compliances_sections_cn .complainces_row .fixed_compliance_col {
  padding: 10px 15px;
  z-index: 7;
}
 
.compliances_sections_cn .row .column > div:last-child { 
  border-right: 0;
}

.compliances_sections_cn .row .section-head h1 {
  font-size: 14px;
  font-weight: 600;
}
 
.compliance_comments .popup-box-menu-comments { 
  background: transparent;
  padding: 1rem;
  z-index: 999 !important; 
  display: flex;
  flex-direction: row;
  z-index: 8;
  justify-content: space-between;
  align-items: start;
  width: 550px;
  height: auto;  
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
}

.compliance_comments .popup-box-menu-comments .cross_close_wrapper { 
  background: #ffffff;
  width: 30px;
  height: 30px;
  z-index: 8;
  box-shadow: 0px 4px 54px rgba(26, 26, 26, 0.25);
  border-radius: 50%;
}

.compliance_comments .popup-box-menu-comments .cross_close_wrapper:hover,
.compliance_comments .comments_icon_wrapper:hover{
  cursor: pointer;
}
  
.compliance_comments .popup-box-menu-comments .cross_close_wrapper img {
  position: relative;
  left: 7px;
  top: 1px;
}

.compliance_comments .comments_box {
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  width: 475px;
  box-shadow: 0px 4px 54px rgba(105, 105, 105, 0.25);
  border-radius: 4px;
  padding: 15px;
}

.compliance_comments .comments_box .compliance_vendor {
  color: #FC5E24;
  font-size: 18px; 
  font-weight: 700;
}

.compliance_comments .comments_box .compliance_applicable {
  font-size: 14px; 
  font-weight: 400;
  color: #565656;
}

.compliance_comments .comments_box .compliance_applicable span {
  font-size: 14px; 
  font-weight: 600;
  color: #2D74E0;
}

.compliance_comments .comments_box .compliance_name {
  font-size: 18px;
  margin-top: 10px;
  font-weight: 600;
  color: #303030;
}

.compliance_comments .comments_box .compliance_comments {
  font-size: 14px;
  margin-top: 10px;
  font-weight: 400;
  color: #565656;
  padding-bottom: 20px;
}
/* Compliances Summary Grid */
 
.compliance_summary_grid {
  padding: 20px 20px 0 20px;
}

.compliance_summary_grid .wrapper {
	border: 1px solid #E6E6E6;
}

.compliance_summary_grid table {
	border-collapse: collapse;
}

.compliance_summary_grid .table-scores {
	table-layout: fixed;
	width: 258px;
	display: inline-block;
	border-right: 1px solid #E6E6E6;
	vertical-align: top;
}
 
.compliance_summary_grid .table-data .score-values {
  display: flex;
  align-items: center;
  justify-content: space-around;  
}

.compliance_summary_grid .table-data .score-values div {
  color: #333333;
  font-weight: 600;
  font-size: 15px;
}

.compliance_summary_grid .table-scores .vertical_col_label_summary,
.compliance_summary_grid .table-data .horizontal_col_labels div {
  color: #2D74E0;
  font-weight: 600;
  font-size: 15px;
}

.compliance_summary_grid .table-scores .vertical_col_label {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.compliance_summary_grid .table-scores th {
	min-width: 258px;
	max-width: 258px;
	text-align: left;
	border-right: 4px solid #E6E6E6;
}

.compliance_summary_grid .table-data-wrapper {
	display: inline-block;
	overflow-x: scroll;
	vertical-align: top;
	width: calc(100% - 258px);
}

.compliance_summary_grid .table-data-wrapper table {
	border-left: 0;
}

.compliance_summary_grid .table-data-wrapper td,
.compliance_summary_grid .table-data-wrapper th {
	min-width: 240px;
	max-width: 240px;
	border-right: 1px solid #E6E6E6;
  padding: 0;
  margin: 0;
}

.compliance_summary_grid .table-data-wrapper th:last-child,
.compliances_sections_cn .complainces_row .compliance_content:last-child {
	border-right: 0;
  display: none;
}

.compliance_summary_grid .table-data-wrapper::-webkit-scrollbar {
  width: 3px;
  height: 5px; 
  cursor: pointer;
} 

.compliance_summary_grid .table-data-wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.compliance_summary_grid .table-data-wrapper::-webkit-scrollbar-track {
  background-color: #E6E6E6;
  cursor: pointer;
}
   
.compliance_summary_grid .table-data-wrapper td:not(:last-child) {
	border-right: 1px solid #E6E6E6;
}

.compliance_summary_grid th {
	height: 48px;
}

.compliance_summary_grid th, .compliance_summary_grid td {
	padding: 0 16px;
}

.compliance_summary_grid thead {
	background: #f1f6ff;
}

.compliance_summary_grid td {
	height: 58px;
}

.compliance_summary_grid thead tr,
.compliance_summary_grid tbody tr:not(:last-child) {
	border-bottom: 1px solid #E6E6E6;
}

.terms_conditions_master_grid .ag-details-row {
  padding: 10px 0 0 0;
}

.common_field_comments_class {
  max-height: 500px; 
  overflow-y: auto; 
  overflow-x: hidden;
  margin-bottom: 20px; 
  background-color: #f9f9f9;
  white-space: pre-wrap;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}
 
.common_field_comments_class::-webkit-scrollbar {
  width: 8px;   
}

.common_field_comments_class::-webkit-scrollbar-thumb {
  background-color: #bababa; 
  cursor: pointer; 
  border-radius: 10px; 
}

.common_field_comments_class::-webkit-scrollbar-thumb:hover {
  background-color: #adadad;  
}

.common_field_comments_class::-webkit-scrollbar-track {
  background: #f3f3f3;  
  border-radius: 10px;   
}

.common_field_comments_class p{
  word-wrap: break-word;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  font-family: Arial, sans-serif; 
}

/* --- */

.rs_form_resources_details_table  .rs_item_selected{
  background: #BCDFFB;
}

.rs_form_resources_details_table .rs_item_row {
  background: #fafafa;
}  


.rs_item_row.rs_item_selected {
  background-color: #a1d5ff;
}

.rs_item_row .rs_item_checkbox { 
  border: 2px solid #333;
  background-color: #f0f0f0;
  cursor: pointer;
}

.detailed_grid_view_renderer {
  padding: 20px 10px;
}

.no-records {
  text-align: center;
  padding: 20px 0;
}

.common-dot-style {
  color: #000000;
}

.colorful-source-name {
  color: #2D74E0;
  margin-right: 2px;
}

.action_history .user-logo-container .user-logo {
  width: 100%;
  height: 100%;
  border-radius: 60%;
  aspect-ratio: 3/2; 
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative; 
}

.rfq-attachment-popup.vendor_reason_popup{
  width: 400px;  
}

.rfq-attachment-popup.vendor_reason_popup h1 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 600;
} 

.vendor_reason_selection_wrapper {
  display: flex;
  flex-direction: column;
  gap: 0px;
  max-height: 200px;
  overflow-y: auto; 
  margin-bottom: 15px;
}

.vendor_reason_selection_wrapper h2 {
  font-size: 16px;
  color: #2D74E0;
  font-weight: 600;
  margin-bottom: 3px;
  white-space: nowrap; 
}

.vendor_reason_selection_wrapper p {
  font-size: 14px; 
  word-wrap: break-word; 
  margin: 0;
  line-height: 1.5;
}

.vendor_reasons_details_wrapper {
  max-height: 100px;
  overflow-y: auto; 
}
 
.vendor_reasons_details_wrapper::-webkit-scrollbar {
  width: 8px;
  background-color: transparent; 
}

.vendor_reasons_details_wrapper:hover::-webkit-scrollbar {
  width: 8px; 
}
  
.vendor_reasons_details_wrapper:hover::-webkit-scrollbar-thumb {
  background-color: rgba(51, 49, 49, 0.295);
  border-radius: 10px; 
  cursor: pointer;
}

/* .rs_item_previleges {
  padding-left: 10px; /* Adjust the spacing as needed  
/* }   */

.rfq-line-view { position: relative; }

.cancel_action_btn {
  width: 124px;
  height: 40px; 
  background: #FFFFFF;
  border: 1px solid #EBEFF2;
  border-radius: 4px;
}

.submit_action_btn {
  width: 124px;
  height: 40px; 
  background: #2D74E0;
  border: 1px solid #EBEFF2;
  color: #fff;
  border-radius: 4px;
}

.reset_action_btn {
  width: 124px;
  height: 40px; 
  background: #FFFF9C;
  border: 1px solid #EBEFF2;
  color: #000000;
  border-radius: 4px;
}

.rfq_head_line_txt {
  text-align: start;  
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 20px;
  line-height: 130%; 
  letter-spacing: 0.01em; 
  user-select: none;
  color: #000000;
}

.search_box_section:hover .search_box_section_inner{
  border: 0 none;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
  
.textarea_default_wrapper textarea {
  width: 100%;
  min-height: 29px;
  max-height: 35px;
  outline: none;
  border-radius: 0px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid #b5b5b5;
}
 
.workflow_action_history_txt {
  text-align: start;  
  font-weight: 700;
  margin-bottom: 1rem;
  font-size: 24px; 
  letter-spacing: 0.01em; 
  user-select: none;
  color: #111827;
}

.rfq_head_line_txt_recall {
  text-align: left;  
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 17px;
  line-height: 130%; 
  letter-spacing: 0.01em; 
  user-select: none;
  color: #000000;
  margin-bottom: 1.5rem;
}

.common_score_class_spacing {
  display: flex;
  justify-content: start;
  flex-direction: row;
  gap: 20px;
}

.technical_common_color_class {
  color: #2D74E0;
  font-weight: 600;
}
  
.financial_common_color_class {
  color: #FC5E24;
  font-weight: 600;
}

.total_common_color_class {
  color: #000000;
  font-weight: 600;
}

.action_history .history-short-details {
  /* z-index: 1000; uncomment if needed in future */
  position: relative; 
}

.action_history .dropdown-menu-task h5 {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  z-index: 1;
}
 
.action_history .action_history_user_comms h3,
.action_history .history-short-details h3 {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
}

.action_history .action_status button {
  background-color: transparent;
  border: none;
  color: #2D74E0;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}

.action_history .action_status .completed,
.action_history .action_status .rejected,
.action_history .action_status .in-process,
.action_history .action_status .cancelled,
.action_history .action_status .recalled {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
}

.action_history .action_status .completed .action-label,
.action_history .action_status .rejected .action-label,
.action_history .action_status .in-process .action-label,
.action_history .action_status .cancelled .action-label,
.action_history .action_status .recalled .action-label {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.action_history .action_status .completed .action-performed {
  color: #4CAF50;
  font-weight: 600;
  font-size: 16px;
  background-color: rgba(76, 175, 80, 0.04);
  padding: 0.4rem 1rem 0.5rem 0.8rem;
  border-radius: 4px; 
  margin-top: 5px;
}
 
.action_history .action_status .rejected .action-performed {
  font-size: 16px;
  font-weight: 600;
  color: #E94335; 
  background-color: rgba(233, 67, 53, 0.04);
  padding: 0.4rem 1rem 0.5rem 0.8rem;
  border-radius: 4px; 
  margin-top: 5px;
}
   
.action_history .action_status .in-process .action-performed{
  font-size: 16px;
  font-weight: 600;
  color: #FDAB3D; 
  background-color: rgba(253, 171, 61, 0.04);
  padding: 0.4rem 1rem 0.5rem 0.8rem;
  border-radius: 4px;
  margin-top: 5px;
}

.action_history .action_status .cancelled .action-performed,
.action_history .action_status .recalled .action-performed{
  color: #000000; 
  font-weight: 600;
  font-size: 16px;
  background-color: rgba(135, 81, 81, 0.04);
  padding: 0.4rem 1rem 0.5rem 0.8rem;
  border-radius: 4px;
  margin-top: 5px;
}
  
.action_history .actions_taken h3 .pending_clr{  
  color: #FC5E24;  
  font-weight: 500;
}

.action_history .actions_taken h3 .default_clr {
  color: #2D74E0;
  font-weight: 500;
}
 
.custom_dropdown_task_actions_grid { 
  display: inline-block;
  margin-bottom: 1.2rem;
}

.custom_dropdown_task_actions_grid .dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.375rem 0.75rem;  
  font-weight: 600;
  background-clip: padding-box;  
  background-color: transparent;
  border: 1px solid #2D74E0;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom_dropdown_task_actions_grid .dropdown-toggle:focus {
  outline: none; 
}

.custom_dropdown_task_actions_grid .rotate-up {
  transform: rotate(180deg);
}

.custom_dropdown_task_actions_grid .recall-item,
.custom_dropdown_task_actions_grid .complete-item,
.custom_dropdown_task_actions_grid .cancel-item,
.custom_dropdown_task_actions_grid .default-item,
.custom_dropdown_task_actions_grid .reject-item,
.custom_dropdown_task_actions_grid .in-process-item, 
.custom_dropdown_task_actions_grid .rfq-line-distribution-item,
.custom_dropdown_task_actions_grid .rfq-techincal-evaluation-item,
.custom_dropdown_task_actions_grid .rfq-financial-evaluation-item,
.custom_dropdown_task_actions_grid .rfq-awarding-item,
.custom_dropdown_task_actions_grid .rfq-line-item {
  list-style-type: none; 
  font-size: 16px;
  margin-bottom: 0;
  padding-bottom: 0; 
}

.custom_dropdown_task_actions_grid .recall-item span:before,
.custom_dropdown_task_actions_grid .complete-item span:before,
.custom_dropdown_task_actions_grid .cancel-item span:before,
.custom_dropdown_task_actions_grid .default-item span:before,
.custom_dropdown_task_actions_grid .reject-item span:before,
.custom_dropdown_task_actions_grid .in-process-item span:before, 
.custom_dropdown_task_actions_grid .rfq-line-distribution-item span:before,
.custom_dropdown_task_actions_grid .rfq-techincal-evaluation-item span:before,
.custom_dropdown_task_actions_grid .rfq-financial-evaluation-item span:before,
.custom_dropdown_task_actions_grid .rfq-awarding-item span:before,
.custom_dropdown_task_actions_grid .rfq-line-item span:before {
  content: "•";
  display: inline-block;
  margin-right: 5px; 
}

.custom_dropdown_task_actions_grid .recall-item { color: #000000; font-weight: 600; }

.custom_dropdown_task_actions_grid .complete-item { color: #32C572; font-weight: 600; }

.custom_dropdown_task_actions_grid .cancel-item { color: #E94335; font-weight: 600; }

.custom_dropdown_task_actions_grid .default-item { color: #000000;  font-weight: 600;}

.custom_dropdown_task_actions_grid .reject-item { color: #E94335; font-weight: 600;}

.custom_dropdown_task_actions_grid .in-process-item { color: #FDAB3D;  font-weight: 600;}

.custom_dropdown_task_actions_grid .rfq-line-distribution-item { color: #E94335; font-weight: 600;}

.custom_dropdown_task_actions_grid .rfq-techincal-evaluation-item{ color: #1976D2; font-weight: 600;}

.custom_dropdown_task_actions_grid .rfq-financial-evaluation-item{ color: #1976D2; font-weight: 600;}
 
.custom_dropdown_task_actions_grid .rfq-awarding-item{ color: #1565C0; font-weight: 600;}

.custom_dropdown_task_actions_grid .rfq-line-item{ color: #FDAB3D; font-weight: 600;}

.custom_dropdown_task_actions_grid .dropdown_menu_task_actions_grid {
  top: 37px;
}

.custom_dropdown_task_actions_grid .dropdown_menu_task_actions_grid,
.custom_dropdown_task_actions_grid .dropdown_menu_action_history {
  position: absolute; 
  z-index: 1005;
  display: block;
  float: left;
  min-width: 240px;
  padding: 1rem 0;
  margin: 0.125rem 0 3rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box; 
  border-radius: 4px; 
}

.custom_dropdown_task_actions_grid .dropdown_menu_task_actions_grid {
  left: -146px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
}

.custom_dropdown_task_actions_grid .dropdown_menu_action_history {
  left: 1px;
  top: 148px;
  box-shadow: 10px 15px 40px 15px rgba(0, 0, 0, 0.08);
}

.custom_dropdown_task_actions_grid .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  font-size: 14px;
  color: #757575;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  transition: 0.1s ease-in-out;
}

.custom_dropdown_task_actions_grid .dropdown-item:hover,
.custom_dropdown_task_actions_grid .dropdown-item:focus {
  color: #ffffff;
  background-color: #2D74E0;
  transition: 0.1s ease-in-out;
}
  
.lines_recommendation_popup_wrapper .lines_details h2 {
  font-size: 20px;
  color: #303030;
  font-weight: 600;
}

.lines_recommendation_popup_wrapper .lines_recommendation_popup .close-action-btn,
.lines_recommendation_popup_wrapper .lines_recommendation_popup .select-action-btn {
  margin-right: 1rem; 
}

.lines_recommendation_popup_wrapper .lines_recommendation_popup .close-action-btn button,
.lines_recommendation_popup_wrapper .lines_recommendation_popup .select-action-btn button {
  padding: 0.3rem 2rem 0.3rem 2rem;
}

.lines_recommendation_popup_wrapper .lines_recommendation_popup .close-action-btn button {
  border: 1px solid #EBEFF2;
  background-color: transparent;
  color: #303030;
  border-radius: 4px;
}

.lines_recommendation_popup_wrapper .lines_recommendation_popup .select-action-btn button {
  border: 1px solid #2D74E0;
  background-color: #2D74E0;
  color: #FFFFFF;
  border-radius: 4px;
}

.user-treeview-hierarchy .rs_tree_view_section {
  overflow: hidden;
}
 
/* RFQ Summary Table CSS - */
.common_scoring_summary_table_wrapper {
  border: 1px solid #E6E6E6;
  padding: 1rem 1rem 0.5rem 1rem;
  border-radius: 4px; 
}
  
.common_scoring_summary_table { 
  overflow: auto;
  min-width: 600px;
}

@media screen and (max-width:1260px) {
  .common_scoring_summary_table { 
    width:100%;
    min-width: 400px;
  }
}

.common_scoring_summary_table::-webkit-scrollbar {
  width: 2px;
  height: 6px;  
  cursor: pointer;
}

.common_scoring_summary_table::-webkit-scrollbar-thumb {
  background-color: #CCCCCC;   
  cursor: pointer;
  border-radius: 10px;
}
  
.common_scoring_summary_table::-webkit-scrollbar-track {
  background-color: #ffffff;   
  cursor: pointer; 
}

.common_scoring_summary_table .table-wrapper {
  width: 100%; 
  cursor: pointer; 
}

.contract-dashboard-screen .employee-dashboard-screen .info-card-common {
  min-width: 240px; 
}

.common_scoring_summary_table_wrapper .summary_main_heading {
  font-size: 18px;
  color: #2D74E0;
  font-weight: 600;
  margin-bottom: 1rem; 
}

.common_scoring_summary_table .table-view .evaluator_type {
  color: #2D74E0;
  font-size: 14px;
  font-weight: 400;
  margin-top: 0.5rem;
}

.common_scoring_summary_table .table-view {
  border-collapse: collapse;
  width: 100%; 
  white-space: nowrap;
}

.common_scoring_summary_table .table-view th,
.common_scoring_summary_table .table-view td { 
  padding: 8px;
  text-align: left;
  min-width: 250px;
}

.common_scoring_summary_table .table-view thead tr {
  border-bottom: none;
}

.common_scoring_summary_table .table-view tr {
  border-bottom: 1px solid #DCDFE3;
}

/* .common_scoring_summary_table .table-view tr:last-child { border: none; } */

.common_scoring_summary_table .table-view th {
  background-color: #F9F9F9;
  color: #050505;
  font-weight: 600;
  font-size: 14px;  
}

.common_scoring_summary_table .rs_input_checkbox { display: block; margin-bottom: 0; }

.checkbox-wrapper .rs_input_checkbox { display: block; margin-bottom: 0; }
/* ---- */

/* Scrollbar Effect CSS TESTING */


.rfq-div-1 { 
  margin-bottom: 10rem;
  overflow-x: auto; 
  width: 400px;
}

.rfq-div-2 { 
  overflow-x: auto;
  width: 400px;
}

.scroll-content {
  display: flex;
  width: 700px;
  flex-direction: row;  
}
    
/* -- */
.action_history .actions_taken h2 {
  font-size: 16px;
  color: #2D74E0; 
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.action_history .actions_taken h4 {
  color: #9C9C9C;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 0;
}

.action_history .list-actions-btn {
  display: flex;
  align-items: start;
  cursor: pointer;
}
 
/* ----- */
.treeview_vendor ul li:last-child ul {
  border-left: 1px solid white;
  margin-left: -17px;
}
 
input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}

rs_prevlieges_details_table, .rs_form_resources_details_table {
  background: #ffffff;
  height: auto;
  border: 1px solid #DDE2EB; 
}

 

.rs_prevlieges_details_table thead tr th,.rs_form_resources_details_table thead tr th {
  font-size: 16px;
  font-weight: 600;
  border: 0 none !important;
  background: #F8F9FA;
  color: #050505; 
  padding: 11px 20px;
}

.rs_prevlieges_details_table tbody tr, .rs_form_resources_details_table tbody tr {
  background: #ffffff;
  font-size: 16px;
  font-weight: 400;
}

.rs_prevlieges_details_table tbody tr td, .rs_form_resources_details_table tbody tr td { 
  padding: 11px 20px;
  border-top: 1px solid #DCDFE3;
}

.rs_prevlieges_details_table, .rs_form_resources_details_table {
  background: #ffffff; 
  height: auto;
  max-height: 100px;  
  overflow-y: scroll;
}

.rs_prevlieges_details_table thead tr th {
  font-size: 16px;
  font-weight: 600;
  border: 0 none !important;
  background:#F8F9FA;
  color: #050505;  
  padding: 11px 20px;
}

.rs_form_resources_details_table thead tr th {
  font-size: 16px;
  font-weight: 600;
  border: 0 none !important;
  background:#F8F9FA;
  color: #050505;  
  padding: 11px 20px; 
  width:33.33%;
}

.rs_form_container .entity-logo-wrapper {
  width: 200px;
}

.rs_previleges_buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.rs_previleges_buttons #add_api {
  background-color: transparent;
  border: none;
  font-weight: 500;
}

.rs_previleges_buttons #delete_api {
  background-color: transparent; 
  border: none;
  font-weight: 500;
}
.rs_previleges_buttons button {
  margin-right: 10px;
}
.rs_prevlieges_details_table tbody tr {
  background: #ffffff;
  font-size: 16px;
  font-weight: 400;
}
.rs_prevlieges_details_table tbody tr td {
  width: 33.33%;
  padding: 11px 20px;
  border-top: 1px solid #DCDFE3;
}

.dropdown-cell {
    background-color: transparent;
    margin: 0;
    padding: 0;
 
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #1F263E;   
    border: none;
    border-right: 0px solid #DCDCDC !important;
    border-left:0.2px solid #DCDCDC !important;  
}
.dropdown-cell:hover {
    background-color: transparent;
}
.dropdown-cell:focus {
    background-color: transparent;
    box-shadow: none;
} 
.k-list {
  font-size:  13px;
} 
.dropdown-cell {
    background-color: transparent;
    margin: 0;
    padding: 0;
 
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #1F263E;   
    border: none;
border-right: 0px solid #DCDCDC !important;
border-left:0.2px solid #DCDCDC !important;  
}
.dropdown-cell:hover {
    background-color: transparent;
}
.dropdown-cell:focus {
    background-color: transparent;
    box-shadow: none;
}  
   
.inner-page-header h2 {

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px; 
    color: #000000;
    }
    
.inner-page-routes a {

    font-style: normal;
    font-weight: 400;
    font-size: 16px; 
    text-decoration: none;
    display: block;
    color: #000000;
    }

.dropdown-body {
    border-top: 1px solid #E5E8EC;
    display: none;
    z-index: 990;
    border: 1px solid #AFAFAF;
    position: relative;
}
      
.dropdown-body.open {
    display: block;
    z-index: 999;
    position: relative; 

    font-style: normal;
    font-weight: 500;
    font-size: 12px; 
    padding: 0px ; 
}
      
      .dropdown-item { 
    
        font-style: normal;
        font-weight: 500;
        font-size: 12px; 
        padding: 0px ;
        line-height: 27px;
      }
      
      .dropdown-item:hover {
        cursor: pointer; 
      }
      
      .dropdown-item-dot {
        opacity: 0;
        color: #91A5BE;
        transition: all .2s ease-in-out; 
      }
      
      .dropdown-item-dot.selected {
        opacity: 1; 
      }
      
      .icon {
        font-size: 13px;
        color: #91A5BE;
        transform: rotate(0deg);
        transition: all .2s ease-in-out;
      }
      
      .icon.open {
        transform: rotate(90deg);
      }  
    
      .dropdown-toggle {
        border-bottom: 1px solid #AFAFAF; 
        width: 10rem; 
        margin-top: 0.1rem; 
        border: none;
      }
    
      .btn-custom {  
        border-bottom: 1px solid #AFAFAF; 
        z-index: 999;
        cursor: pointer; 
        position: relative;
    
        font-style: normal;
        font-weight: 500;
        border-radius: 0px;
        font-size: 12px;  
      }
       
      .dropdown-toggle::after {
       display: none;
      } 
    
      .dropdown-toggle:focus{
        box-shadow: none;
      }
       
      .background-layout-forms {
        background-color: rgba(255, 255, 255, 0.56);
      }
.k-toolbar {
  Background: #fff ; 
}  

.k-filtercell .k-input {
  height: 2.2rem ; 
}

.k-input {
  height: 2em;
font-style: normal;
font-weight: 400;
font-size: 14.5756px;
line-height: 17px;
}

label {
cursor: pointer;
font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 24px; */
    display: flex;
    align-items: center;
    color: #7E7E7E;
    /* user-select: none; */
}

.form-resources {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #7E7E7E;
}

.app_master_area{}
.sidebar_contact_wrapper {
	display: block;
	margin: 0;
	padding: 0;
	transition: all .5s;
}
.app_master_area .contents_with_header{
  display: block;
}

.common_description_textarea {
  width: 100%;
  min-height: 200px;
  max-height: 250px;
  outline: none; 
}

.my_account_page .user_icon_box .icon_round, 
.main_header .user_widget_menu .u_p_wraper{ 
  background-position: top center !important; 
}

.common_description_textarea:focus,
.common_description_textarea:focus-visible {
  outline: none; 
}

.system-params-section-pads .sidebar_section {
  width: 100% !important;
  z-index: 7 !important;
}

.app_master_area .sidebar_section { 
	margin: 0;
	padding: 0;
	width: 250px;
	max-width: none;
  transition: all .2s;
  white-space: nowrap;
  position: -webkit-sticky;
  position: sticky; 
  float: left;
  top: 0;
  min-height: 100vh;
  z-index: 10;
}
.sidebar_contact_wrapper>.sidebar_section{
  z-index: 100;
}

.sidebar_section .main_logo {
	display: block;
	width: 100%;
	text-decoration: none;
	background: #21263D;
	padding: 26px 20px;
	text-align: center;
  white-space: nowrap;
}
.sidebar_section .main_logo img{}
.rs_master_canvas {
	padding: 0 30px;
}

.language-select .dropdown-menu{}
.language-select .dropdown-menu {}
.language-select .dropdown-menu li {
	cursor: pointer;
	padding: 5px 10px;
  transition: all .5s;
  color: #303030;
}
.language-select .dropdown-menu li:hover{
  background-color:#E7E9EB;
}
.language-select .dropdown-menu  li:empty{
  display: none;
}
.language-select .dropdown-menu  li a{}

select.form-control {
	border: 0 !important;
	border-radius: 0 !important;
	background-image: url('../public/images/icons/angle-down.svg');
	background-position: center right;
	background-repeat: no-repeat;
	background-color: transparent;
	background-size: 11px;
	border-bottom: 1px solid #AFAFAF !important;
  outline: none !important;
  box-shadow: none !important;
}
.rs_btn {
	background: transparent;
	color: #000000;
	font-size: 16px;
	font-weight: 600;
	border-radius: 4px;
	border: 1px solid #000000;
	padding: 7px 20px;
  transition: all .5s;
  display: inline-flex;
  align-items: center;
  gap: 3px;
}
.rs_btn:hover{
  opacity: .8;
  box-shadow: none;
  outline: none;
}
.rs_btn.btn_active{
  background-color: #FC5E24;
  color: #fff;
  border-color: #FC5E24;
}
.rs_btn.btn_active:hover{}
.rs_btn .icon {
	height: 17px;
}
.archive_header_section {
  justify-content: space-between;
  display: flex;
  border-bottom: 1px solid #DEE8F4;
  padding: 10px 0;
  align-items: center;
  margin-bottom: 25px;
  z-index: 9 !important;  /* remove this prop here */
}

.archive_header_section > div{}
.archive_header_section > div{}
.rs_inactive_label,
.rs_active_label {
	background: linear-gradient(0deg, rgba(18, 189, 178, 0.11), rgba(18, 189, 178, 0.11)), #FFFFFF;
	text-align: center;
	display: inline-block;
  padding: 4px 14px;
  color: #3B3E45;
  border-radius: 4px;
  margin: 0 auto;
  height: auto;
  line-height: normal;
}
.rs_active_label_normal {
	/* background: linear-gradient(0deg, rgba(18, 189, 178, 0.11), rgba(18, 189, 178, 0.11)), #FFFFFF; */
	text-align: center;
	display: inline-block; 
  color: #3B3E45; 
  margin: 0 auto;
  height: auto;
  line-height: normal;
}
.rs_inactive_label {
	background:linear-gradient(0deg, rgba(233, 67, 53, 0.11), rgba(233, 67, 53, 0.11)), #FFFFFF;
}
.rs_active_label_invoice {
  background: rgba(45, 116, 224, 0.1);
	text-align: center;
	display: inline-block;
  padding: 4px 14px;
  color: #2D74E0;
  border-radius: 4px;
  margin: 0 auto;
  height: auto;
  line-height: normal;
}
.rs_active_label_employee {
  background: linear-gradient(0deg, rgba(145, 2, 2, 0.11), rgba(145, 2, 2, 0.11)), #FFFFFF;
	text-align: center;
	display: inline-block;
  padding: 4px 14px;
  color: #3B3E45;
  border-radius: 4px;
  margin: 0 auto;
  height: auto;
  line-height: normal;
}
.rs_active_label_vendor {
  background: linear-gradient(0deg, rgba(18, 189, 178, 0.11), rgba(18, 189, 178, 0.11)), #FFFFFF;
  text-align: center;
	display: inline-block;
  padding: 4px 14px;
  color: #3B3E45;
  border-radius: 4px;
  margin: 0 auto;
  height: auto;
  line-height: normal;
}
.rs_inactive_label_invoice {
  background: rgba(0, 0, 0, 0.1);
	text-align: center;
	display: inline-block;
  padding: 4px 14px;
  color: #ffffff;
  border-radius: 4px;
  margin: 0 auto;
  height: auto;
  line-height: normal;
}

.background-loader-overlay {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 1000;
  overflow: hidden;
}

.main_header .search_box_section .options_box { padding: 0 !important } 
.main_header .search_box_section .options_box .select2-selection__rendered { padding-left: 10px !important }
.main_header .search_box_section .options_box .select2-container--default .select2-selection--single .select2-selection__placeholder { padding-left: 1px !important } 

.rs_completion_status {
  background: linear-gradient(0deg, rgba(18, 189, 178, 0.11), rgba(18, 189, 178, 0.11)), #FFFFFF;
	text-align: center;
	display: inline-block;
  padding: 4px 14px;
  color: #3B3E45;
  border-radius: 4px;
  margin: 0 auto;
  height: auto;
  line-height: normal;
}
.rs_pending_status {
  background: linear-gradient(0deg, rgba(22, 93, 255, 0.11), rgba(22, 93, 255, 0.11)), #FFFFFF;
	text-align: center;
	display: inline-block;
  padding: 4px 14px;
  color: #3B3E45;
  border-radius: 4px;
  margin: 0 auto;
  height: auto;
  line-height: normal;
}
.rs_rejection_billing {
  background: linear-gradient(0deg, rgba(254, 31, 31, 0.11), rgba(254, 31, 31, 0.11)), #FFFFFF;
	text-align: center;
	display: inline-block;
  padding: 4px 14px;
  color: #3B3E45;
  border-radius: 4px;
  margin: 0 auto;
  height: auto;
  line-height: normal;
}
.partially-cell-cls {
  color: #FDAB3D;
  font-weight: 600;
}
.n-a-cell-cls {
  color: #E94335;
  font-weight: 600;
}
.fully-cell-cls {
  color: #2D74E0;
  font-weight: 600;
}
.default-cell-cls {
  color: #000000;
  font-weight: 600;
}
body .collapsible .header-collapsed,
body .collapsible .header-expanded {
  padding: 10px 20px;
}
.collapsible .content {
  border-left: 1px solid #DEE8F4;
  border-radius: 0;
  border-right: 1px solid #DEE8F4;
  border-bottom: 1px solid #DEE8F4;
  padding: 20px;
}
.collapsible .content .tenant-address,
.collapsible .content .tenant-fields-section{
  border: 0;
}

.purchase_orders_listing_archive .archive_header_section {
  background-color: #F7F9FB;
}

.purchase_orders_detail_view .archive_header_section {
  background-color: #F7F9FB;
}

.image_section_employee {
  height: 289px; 
  background: #FAFAFA;
  border: 1px solid #DEE8F4;
}
 
.products_img {
  border-bottom: 1px solid #DEE8F4;;
  padding-left: 25px;
  padding-right: 25px;
}

.product_image_section_item_master .products_img button {
  background-color: transparent;
  border: none;
}

 
.product_image_section_item_master .products_img h2 {  
  font-size: 16px; 
  text-transform: capitalize; 
  color: #2D74E0;
  font-weight: 600;  
  border-bottom: 3px solid #2D74E0;
  padding-bottom: 10px;
  border-bottom-width: 3px;
  border-bottom-style: solid; 
}

.product_image_section_item_master .product_images_area {
  padding-left: 25px;
  padding-right: 25px;
  overflow-y: scroll;
  width: 100%;
  overflow-x: hidden;
  height: 75px;
}

.product_image_section_item_master .product_images_area::-webkit-scrollbar {
  width: 6px;  
}

.product_image_section_item_master .product_images_area::-webkit-scrollbar-thumb {
  background-color: #b9b9b9; 
  border-radius: 10px;
}

.product_image_section_item_master .product_images_area::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.product_image_section_item_master .product_images_area::-webkit-scrollbar-track {
  background-color: #ffffff;  
}

.image_uploader_wrapper {
  width: 175px;
  height: 175px;
  background: #fff; 
  border-radius: 50%;
  box-sizing: border-box; 
  position: relative;
  margin: 12px 10px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.product_images_area .cross_btn_product {
  width: 20px;
  height: 20px;  
  border-radius: 55px;
  font-size: 13px;
  border: none;
  z-index: 100;
  color: #ffffff;
  background: #343434; 
}

.product_images_area .product_img {
  width: 80px;
  height: 80px;
}

.product_images_area .item_product_img {
  width: auto;  
  max-width: 80px;  
  max-height: 80px; 
  transition: transform 0.3s;
  position: relative;
  z-index: 99;
}
 
.product_images_area .item_product_img:hover {
  transform: scale(1.1); 
  z-index: 2;
  cursor: pointer;
}

.product_images_area .file_uploader_product {
  cursor: pointer;
}

.file_name_wrapper span {
  color: #007BFF;
  cursor: pointer;
  text-decoration: underline;
}
  
.image_elements {
  border-bottom: 1px solid #DEE8F4;
  padding-left: 25px;
  padding-right: 25px;
}

.image_elements button {
  border: none;
  background-color: transparent;
}

.image_elements h2 {
  font-size: 16px; 
  text-transform: capitalize; 
  color: #000000;
  font-weight: 600;  
  border-bottom: 3px solid #FC5E24;
  padding-bottom: 10px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

.image_section_employee .employee_icon {
  border-radius: 50%;
  width: 200px;
  height: 190px;
  position: relative;
}
 
.purchase_orders_archive .rs_active_billing {
  font-weight: 400;
  font-size: 13px; 
  color: #32C572; 
}

.purchase_orders_archive .rs_inactive_billing {
  font-weight: 400;
  font-size: 13px;
  color: #E94335;
}

.request_for_quotation_archive {
  background-color: #F7F9FB;
}

.request_for_quotation_archive .rs_ag_header {
  background-color: #fff;
}

.request_for_quotation_archive .ag_grid_footer {
  background-color: #fff;
}

.request_for_quotation_archive .ag_grid_wrapper { 
  background-color: #fff;
}

.request_for_quotation_detail_screen .tabs_default > div > div > .lynkaz_tab_nav{ display:none; }

.request_for_quotation_detail_screen .bidding_tab {
  background: #ffffff;
  border: 1px solid #DEE8F4;
  border-radius: 4px;
}
.request_for_quotation_detail_screen .rs_master_canvas_wraper {
  background-color: #F7F9FB;
}
.request_for_quotation_detail_screen .rs_master_canvas_wraper .archive_header_section {
  background-color: #F7F9FB;
}
.request_for_quotation_detail_screen .rs_collapse {
  background-color: #fff;
  border-radius: 4px;
}
.rfq_print_btn .icon {
  height: 20px;
} 

.request_for_quotation_detail_screen .request_for_quotation_content {
  box-sizing: border-box;  
  height: 172px; 
  background: #FDFDFD;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
}

.grid_label_rfq { 
  font-weight: 700;
  font-size: 18px;  
  align-items: center; 
  color: #2D74E0;
}

.rfq_category_btn {
  border: none;
  background-color: transparent;
  color: #000000;
  font-weight: 600;
}

.timelapse .time_left {
  margin-bottom: 1rem;
}

.timelapse { 
  padding: 1.5rem 2rem 1.5rem 2rem; 
  background: rgba(45, 116, 224, 0.08);
  border-radius: 4px;
}

.timecount_box_value {  
  font-weight: 700;
  font-size: 24px; 
  text-align: center; 
  color: #FFFFFF;
  padding-top: 0.5rem;
}
.hide_sidebar .timelapse {
  display: none;
}

.timecount_box_period { 
  font-weight: 400;
  font-size: 18px; 
  color: #FFFFFF;
}

.timecount_box {
  background: #2D74E0;
  border-radius: 4px;
  width: 87px;
  text-align: center;
  height: 84px;
}

.timelapse .time_left h2 { 
  font-weight: 600;
  font-size: 18px; 
  color: #2D74E0; 
  text-align: start;
}
.grid_label_rfq_commercial { 
  font-weight: 700;
  font-size: 14px; 
  color: #2D74E0;
}

.grid_label_rfq_commercial span {
  color: #333333;
}

.bidding_requirement h2{
  font-weight: 700;
  font-size: 18px;
  color: #2D74E0; 
}

.border-bottom-h2 {
  position: relative;
  display: inline-block;
}

.border-bottom-h2::after {
  content: "";
  position: absolute;
  bottom: -2px; /* adjust this value as needed */
  left: 0;
  width: 100%;
  border-bottom: 1px solid #2D74E0
  ; /* change border color and size as desired */
}

.request_for_quotation_detail_screen .bidding_tab .lynkaz_tab_nav {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.request_for_quotation_detail_screen .rs_master_canvas_wraper .bidding_tabs .lynkaz_tab_nav_section .lynkaz_tab_nav {
  z-index: none !important;
  opacity: none !important;
  position: none !important;
}

.request_for_quotation_archive .archive_header_section {
  background-color: #F7F9FB;
}

.order_total {
  /* max-width: 350px; */ 
  width: 100%;
  max-height: 228px;
  min-height: auto;
  background: #222934;
  padding-top: 12px;
  padding-bottom: 12px;
}

.order_total h3 {
  font-weight: 600;
font-size: 22px; 
color: #FFFFFF; 
}

.order_total .numeric_text_orders h2 {
  font-weight: 400;
font-size: 12px; 
color: rgba(255, 255, 255, 0.8);
}

.order_total .numeric_text_orders h2 span {
  font-weight: 400;
font-size: 12px; 
color: #FFFFFF;
}

.subtotal_text {
  border-top:  1px solid #FC5E24;
  padding-top: 3px;
  border-bottom:  1px solid #FC5E24;
}

.select2-container--default .select2-selection--single .select2-selection__arrow{
  background-image: url('../src/assets/arrow-dropdown.svg');
}

.select2-selection--multiple::after {
  content: "";  
  height: 26px;
  position: absolute;
  top: 6px;
  right: 8px;
  width: 20px;
  background-image: url('../src/assets/arrow-dropdown.svg');  
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 11px auto;
}

.select2-selection--multiple.select2-selection--clearable .select2-selection__clear{
   right:15px !important;
}
  
.rs_active_label_actions{
  text-align: center;
	display: inline-block;
  padding: 4px 14px;
  color: #3B3E45;
  border-radius: 4px;
  margin: 0 auto;
  height: auto;
  line-height: normal;
}

.rs_calltoaction_section .actions_tags span.drafted { background-color: #808080 };

.rs_ag_item_search_editor .table {
  table-layout: fixed; 
  width: 100%; 
}

.rs_ag_item_search_editor .table th, 
.rs_ag_item_search_editor .table td {
  max-width: 220px; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;  
} 

.attachments-container::-webkit-scrollbar {
  width: 8px;
}

.attachments-container::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.attachments-container::-webkit-scrollbar-thumb {
  background-color: transparent; 
  border-radius: 10px; 
  cursor: pointer;
}

.attachments-container:hover::-webkit-scrollbar-thumb {
  background-color: #D9D9D9; 
}

 