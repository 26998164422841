.external_home_user {
    background: #F7F9FB;
    height: 100%;
    overflow-y: auto;
    user-select: none;
    max-height:calc(100vh - 82px);
    min-height:calc(100vh - 82px);
}

.external_vendor_profile {
    background: #F7F9FB;
    padding: 0 30px;
}

.external_home_user .rs_master_canvas { padding: 0; }

.external_home_user .document_info {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.external_home_user .document_info h2 {
    font-size: 16px;
    font-weight: 400;
    color: #565656;
}

.external_home_user .document_info h2 span {  
    color: #303030; 
    font-weight: 600;
}

.external_home_user .lynkaz_tab .lynkaz_tab_nav { margin-bottom: 15px; }
 
.external_home_user .ext_container{ padding: 1.5rem 4rem 1.5rem 4rem; }

.feed_identity_user .profile_image { margin: -38px auto 1.2rem; }

.feed_identity_user .card-img-top { height: 80px; }

.feed_identity_user .card{
    width: 100%;
    max-width: 327px; 
    min-height: 325px;
    background: #FFFFFF;
    border: 1px solid #DEE8F4;
    border-radius: 4px;
}

.feed_identity_user .card h3{ 
    font-size: 18px;
    color: #000000;
}

.feed_identity_user .card p {  
    font-size: 16px;
    color: #565656;
    padding: 5px; 
}

.feed_identity_user .card button {
    width: 162px;
    height: 40px;
    background: #FC5E24;
    border-radius: 4px;
    border: none; 
    font-size: 16px; 
    margin-bottom: 1rem;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #FFFFFF; 
}

.request_info_user {
    width: 100%;
    max-width: 327px; 
    min-height: 325px; 
    background: #FFFFFF;
    border: 1px solid #DEE8F4;
    border-radius: 4px;
}

.rate_user { 
    width: 100%;
    max-width: 327px; 
    min-height: 325px;  
    background: #FFFFFF;
    border: 1px solid #DEE8F4;
    border-radius: 4px;
}

.rate_user h3 {
    font-size: 18px; 
    font-weight: 600;
    color: #000000; 
}

.request_info_user  h3 {
    font-size: 18px; 
    font-weight: 600;
    color: #000000; 
}

.user_feed_area .feed_header { background: #fff; }
 
.user_feed_area .feed_header .lynkaz_tab .lynkaz_tab_nav .lynkaz_tab_nav_item p {
  font-size: 14px;
  color: #8C8C8C;
}

.user_feed_area .feed_header .lynkaz_tab .lynkaz_tab_nav .lynkaz_tab_nav_item.active p {
    font-size: 14px;
    color: #FC5E24;
}

.user_feed_area .user_feed_info_tab {
    background: #FFFFFF;
    width: 100%;
    user-select: none;
    height: 89px;
    border-radius: 5px;
    box-shadow: 0px 44px 255px -2px #ccccccf2;
    margin-bottom: 1.5rem;
    margin-right: 1rem;
    padding-top: 0.5rem;
}

.user_feed_area .user_feed_info_tab h2 {   
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 1.59px;
}

.user_feed_area .user_feed_info_tab p { 
    font-size: 14px; 
    color: #4E5969;
    font-weight: 700;
}

.user_feed_area .user_feed_info_tab .icon{
    width: 58px;
    height: 58px;
}

.feed_tabs_items {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: flex-start;
}

.feed_header   {
    background: #FFFFFF;
    border: 1px solid #DEE8F4;
    border-radius: 4px;
} 

.feed_header .invoice_btn {
   float: right;
   margin-bottom: 1rem; 
   margin-right: 1.3rem; 
}

.feed_header .invoice_btn button {
    border: none;
    background: none;
    color: #FC5E24;
    font-size: 14px;
}

.invoice_info_header {
    width: 100%;
    height: 33px;
    background: #3D4669;
    margin-bottom: 1rem;  
    border-top-right-radius: 5px; 
    border-top-left-radius: 5px; 
}

.feed_tabs_invoice .invoice_info_header h2{
    font-size: 14px; 
    color: #FFFFFF; 
    font-weight: 600; 
}

.invoice_info_header_close {
    float: right;
    margin-top: 0.2rem;
    margin-right: 1rem;
}

.user_feeds { 
    height: auto;
    background: #FFFFFF;  
    box-shadow: 0px 0px 2px 1px rgba(112, 112, 112, 0.2);
    border-radius: 10px;
    padding:1rem;
}

.user_feeds .user_feeds_reactions {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 1rem;
    gap: 10px;
}

.user_entity_textarea {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 1rem;
}

.user_entity_textarea .entity_logo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width:40px;
}

.user_entity_textarea .entity_logo .user-profile{
    border-radius: 50%;
    border:2px solid #303030;
    min-width:40px;
    background:#F9F9F9;
    height: 40px;
    object-fit: cover;
}
  
.user_entity_textarea .note_form {
    display: block;
    position: relative;
    width: 100%; 
}

.user_entity_textarea .note_form input {
    display: block;
    width: 100%;
    background: #fff;
    border: 1px solid #F1F1F1;
    height: 64px;
    border-radius: 4px;
    font-size: 14px;
    padding: 0 40px 0 10px;  
}

.user_entity_textarea .comment-box{
    background-color: #F9F9F9;
    padding:1rem;
}

.user_entity_textarea .comment-box .user-name-style{
    font-size:16px;
    font-weight:700;
}

.user_entity_textarea .comment-box p{
    margin-bottom:0;
}

.user_entity_textarea .note_form input:focus {
    outline: none; 
}

.user_entity_textarea .note_form button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #7D879E;
    border: 0 none;
    text-align: center;
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    cursor: pointer;
    transition: all .4s; 
}

.user_entity_textarea .note_form button:hover {
    background-color: #FC5E24; 
}

.user_entity_textarea .note_form img {
    width: 15px;
    margin-top: 2px; 
}
 
.user_feeds .user_feeds_reactions > div { display: flex; justify-content: center; align-items: center; }

.user_feeds .user_feeds_reactions > div h2{
    font-size: 14px;
    color: #707070;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.user_feeds h2 {
    font-size: 20px;
    line-height: 20px; 
    color: #191919;
}

.user_feeds .acknowledge-title{
    color:#2D74E0 !important;
}
 
.user_feeds .document_details h2 {
    color: #2D74E0;
    font-weight: 700;
    cursor: pointer;
}

.user_feeds .document_details h2:hover{
    color:#FC5E24;
}

.user_feeds .company_info .list_actions_btns {
   border: 1px solid #F1F1F1; 
   padding: 10px 20px 10px 20px;
   cursor: pointer;
   user-select: none;
}

.user_feeds .company_info  p {
    font-size: 16px;
    line-height: 16px; 
    color: #707070; 
}

.user_feeds .company_info p span img{ cursor: pointer; }

.user_feeds .post_msg {
    font-size: 14px;
    line-height: 20px; 
    color: #191919; 
}

.entity_wrapper {
    margin-left: 1rem; 
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
}  

.entity_wrapper h3 {
    font-size: 18px; 
    font-weight: 600;
    padding: 0;
    margin: 0;
    color: #303030;
}

.entity_wrapper p {
    font-size: 14px; 
    padding: 0;
    margin: 0;
    color: #707070;
}

.user_feed_area .link-style:hover{
  color:#FC5E24;
}
 

.external_feed_comments h3{
    font-size:16px;
    color:#565656;
    margin-top:16px;
    margin-bottom:16px;
}

.external_feed_comments .comment-box{
    background:#F2F2F2;
    border-radius: 4px;
}

.external_feed_comments .loading-btns{
    font-weight:600;
    color:#565656;
    font-size:16px;
    margin-top:1rem;
    margin-bottom:0;
    cursor: pointer;
}

.feed-support-purple{
    background: linear-gradient(90deg, #ACA5D6 0%, #6F84D2 100%);
    border-radius: 8px;
    padding:1.5rem;
}

.feed-support-blue{
    background-color: #006C9C;
    padding:1rem 1.5rem 1rem 1.5rem;
    border-radius: 8px;
    margin-top:1rem;
    position:relative;
}

.support-title{
    font-size:16px;
    font-weight:600;
    color:#fff;
}

.support-info-text{
    color:#fff;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
}

.support-info-text:hover{
    color:#fff;
}

.get-started-btn{
    width:100%;
    background:#fff;
    height:48px;
    color:#2D74E0;
    border:0;
    border-radius: 4px;
    font-weight:600;
}

.profile-style{
    position:absolute;
    right:0;
    top:2px;
}

.how-to-info-main{
    border-radius: 8px;
    background-color: #fff;
    padding:16px 16px 16px 24px;
}

.how-to-title{
  color:#303030;
  font-weight: 600;
  font-size:22px;
  margin-bottom: 0;
}

.how-to-subtitle{
  font-size:16px;
  line-height:28px;
  color:#565656;
}
 
.common-dot-style{
  width:12px;
  height:12px;
  border-radius: 50%;
}

.green-dot-style{
  background:#00AB55;
}

.yellow-dot-style{
  background:#FFAB00;
}

.pink-dot-style{
    background-color: rgb(255, 70, 151);
}

.blue-dot-style{
  background:#00B8D9;
}

.orange-dot-style{
  background: #FF5630;
}

.link-how-to-title{
    text-decoration: none;
}

.link-how-to-title:hover .how-to-text-style{
    color:#2D74E0 !important;
}

.how-to-text-style{
 margin-bottom: 0px;
 color:#212B36;
 font-weight:600;
 font-size:14px;
}

.user-feed-main-grid{
   display:grid;
   grid-template-columns:3fr 1fr;
   gap:2rem;
   margin-top:1rem;
}

.user_entity_textarea .note_form input::placeholder{
    font-size: 15px;
}