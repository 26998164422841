.dashboard-nav-main .lynkaz_tab .lynkaz_tab_nav {
    margin-bottom: 0;
}

.dashboard-nav-main .lynkaz_tab_nav {
    box-shadow: 0px 11px 11px -5px rgba(171, 171, 171, 0.18);
}

.my_request_collapse .lynkaz_tab_nav {
    box-shadow: none;
    padding-left: 1rem;
}

.my_request_collapse .rs_collapse_contents {
    padding: 0;
}
