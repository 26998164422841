/* .new-bank-account label {
    font-family: 'Titillium Web';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 24px;
display: flex;
align-items: center;
color: #7E7E7E;
} */

.new-bank-account {
    border: 1px solid #DEE8F4;
}
 
 
/*  
.edit-bank-accounts label {
    font-family: 'Titillium Web';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 24px;
display: flex;
align-items: center;
color: #7E7E7E;
}
   */
.new-bank-account {
    min-height: 120vh;
    max-height: auto;
}

.edit-bank-accounts { 
    min-height: 120vh;
    max-height: auto;
}
/* 
.edit-bank-accounts input[type='text'] {
    border-bottom: 1px solid #7E7E7E;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
    width: 100%;
}

.edit-bank-accounts input[type='number'] {
    border-bottom: 1px solid #7E7E7E;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
    width: 100%;
}

.edit-bank-accounts input::placeholder {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
}

.edit-bank-accounts input {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
} 


.new-bank-account input[type='text'] {
    border-bottom: 1px solid #7E7E7E;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
    width: 100%;
}

.new-bank-account input[type='number'] {
    border-bottom: 1px solid #7E7E7E;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
    width: 100%;
}

.new-bank-account input::placeholder {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
}

.new-bank-account input {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
}  */

.new-bank-account .form-group select.form-control {
    font-size: 12px !important;
}

.edit-bank-accounts .form-group select.form-control {
    font-size: 12px !important;
}

.create-bank-account-grid .rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}

.edit-bank-account-grid .rs_grid_btn_save {
    z-index: -10000;
    opacity: 0;
    position: absolute;
}