.label h1{
    /* font-family: 'Titillium Web'; */
font-style: normal;
font-weight: 600;
font-size: 18px;
color: #333333;
}

/* .label-form label {
    /* font-family: 'Titillium Web'; 
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 24px;
display: flex;
align-items: center;
color: #7E7E7E;
} */

.label-form input[type='text'] {
    border-bottom: 1px solid #AFAFAF ;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none;
    width: 100%;
}

input::placeholder {
    /* font-family: 'Titillium Web'; */
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
}
  
input {
    /* font-family: 'Titillium Web'; */
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
} 
  