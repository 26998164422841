/* CSS Styles for All Team Evaluation / Technical - Financial Based (RFQ) Components */
 
/* Team Evaluation Wrapper - CSS */

.technical_evaluation_module_wrapper .lynkaz_tab .lynkaz_tab_contents .lynkaz_tab_contents_content { width: 60%; }
.technical_evaluation_module { width: auto; margin-bottom: 3rem };

.technical_evaluation_with_consolidated_view_wrapper { 
  width: auto;   
};

.technical_evaluation_section { border: 1px solid #E6E6E6 }

.tech_evaluation_master_wrapper {
  padding: 0;
  margin: 0;
  border-radius: 8px;
  min-height: 600px;
  max-height: auto;  
  padding-bottom: 20px;
  border-radius: 8px; 
  height: auto; 
  border: 1px solid #E6E6E6;
}
  
/* Technical Evaluation With Consolidated View - CSS */

.technical_evaluation_filtering { 
  display: flex;
  justify-content: start;
  width: 280px;
  align-items: center;
  gap: 30px;
  padding: 15px 0;
}

.technical_evaluation_filtering .technical_evaluation_preview_tab_wrapper img {
  width: 25px;
  height: auto;
  cursor: pointer;
}

.technical_evaluation_filtering .user_for_scoring_dropdown {
  flex-grow: 1; 
}

/* Team Evaluation Consolidated Header - CSS */ 

.technical_evaluation_consolidated .grid-container > .rs_popup_section .popup_contents .row,
.technical_evaluation_consolidated .lines_recommendation_popup_wrapper .dt_addtional_info .row,
.technical_evaluation_consolidated .rs_popup_section .popup_contents .row {
  flex-wrap: wrap;
}

.technical_evaluation_consolidated .scrollable {
  position: relative;
  overflow: auto;
  white-space: nowrap;    
  box-sizing: border-box; 
  height: 100%;
  /* max-height: 450px !important; */
}

.technical_evaluation_consolidated .grid-container {
  display: grid; 
  position: relative;
  box-sizing: border-box;  
  grid-template-columns: 230px 1fr 230px;  
}

.technical_evaluation_consolidated .column { 
  overflow-y: auto;
  overflow-x: scroll;
  background-color: #ffffff;
  box-sizing: border-box;
  height: 100%;
  scrollbar-width: none;  
  -ms-overflow-style: none;
}

.technical_evaluation_consolidated .column::-webkit-scrollbar {
  display: none;
}

.technical_evaluation_consolidated .sticky-behaviour {
  position: sticky; 
  top: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  z-index: 100;
}

.technical_evaluation_consolidated .evaluation-criteria-heading {
  display: flex;
  align-items: center;
  background-color: #273A59;
  color: #ffffff;
  padding: 15px 20px;
  font-size: 14px;
  font-weight: 600;
}

.technical_evaluation_consolidated .evaluation-criterias {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  color: #000000;
  padding: 15px 20px;
  font-size: 14px;
  border: 1px solid #E6E6E6;
  font-weight: 600;
}

.technical_evaluation_consolidated .evaluation-summaries {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  color: #2D74E0;
  padding: 15px 20px;
  font-size: 14px;
  border: 1px solid #E6E6E6;
  font-weight: 500;
}

.technical_evaluation_consolidated .evaluation-all-scores {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  background-color: #ffffff;
  border: 1px solid #E6E6E6;
  color: #2D74E0;
  font-size: 14px;
  font-weight: 600;
}

.technical_evaluation_consolidated .evaluation-points,
.technical_evaluation_consolidated .summary-heading {
  display: flex;
  align-items: center;
  justify-content: start;  
  background-color: #F1F6FF; 
  color: #2D74E0;
  font-size: 14px;
  font-weight: 600;
}

.technical_evaluation_consolidated .summary-heading { padding: 13px 15px; }

.technical_evaluation_consolidated .evaluation-section-heading {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 15px 12px;
  background-color: #F1F6FF; 
  color: #2D74E0;
  font-size: 14px;
  font-weight: 600;
}

.technical_evaluation_consolidated .evaluation-section-summary {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 15px 12px;
  background-color: #F1F6FF; 
  color: #2D74E0;
  font-size: 14px;
  font-weight: 600;
}

.technical_evaluation_consolidated .evaluation-scoring-points {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  background-color: #F1F6FF; 
  color: #2D74E0;
  font-size: 14px;
  font-weight: 600;
}

.technical_evaluation_consolidated .empty-heading-space{
  height: 47px;
  border: none;
}

.technical_evaluation_consolidated .empty-summary-col {
  height: 50px;
  border: none;
}

.technical_evaluation_consolidated .evaluation-scoring-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  background-color: #F1F6FF; 
  color: #2D74E0;
  font-size: 14px;
  font-weight: 600;
}

.technical_evaluation_consolidated .evaluation-scoring-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  background-color: #273A59;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}

.technical_evaluation_consolidated .scrollable::-webkit-scrollbar,
.technical_evaluation_consolidated .column::-webkit-scrollbar {
  width: 0;
}
  
.technical_evaluation_consolidated .users_div::-webkit-scrollbar,
.technical_evaluation_consolidated .vendor_scoring_div::-webkit-scrollbar{
  height: 6px;
}

/* .technical_evaluation_consolidated .scrollable::-webkit-scrollbar-track, */
.technical_evaluation_consolidated .users_div::-webkit-scrollbar-track,
.technical_evaluation_consolidated .vendor_scoring_div::-webkit-scrollbar-track{
  background-color: transparent;
}

/* .technical_evaluation_consolidated .scrollable::-webkit-scrollbar-thumb, */
.technical_evaluation_consolidated .users_div::-webkit-scrollbar-thumb,
.technical_evaluation_consolidated .vendor_scoring_div::-webkit-scrollbar-thumb{
  background-color: #E6E6E6;
  border-radius: 4px;
}

/* .technical_evaluation_consolidated .scrollable::-webkit-scrollbar-thumb:hover, */
.technical_evaluation_consolidated .users_div::-webkit-scrollbar-thumb:hover,
.technical_evaluation_consolidated .vendor_scoring_div::-webkit-scrollbar-thumb:hover{
  background-color: #E6E6E6;
}

.technical_evaluation_consolidated .row {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0;
  padding: 0;
  z-index: 1;
  flex-direction: row;
  flex-wrap: nowrap;
}

.technical_evaluation_consolidated .scrollable_row { 
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  z-index: 1;
}
 
/* Users CSS  */

.technical_evaluation_consolidated .users_col {
  min-height: 53px;
  max-height: 100%;
  padding: 15px 0px; 
  background-color: #F1F6FF;
  border: 1px solid #E6E6E6;
  color: #000000;
  font-weight: 600;
  font-size: 14px;   
  flex-grow: 1;  
  flex-basis: 300px;  
  display: flex; 
  justify-content: space-around;
  gap: 20px;
}
  
.technical_evaluation_consolidated .users_col .user_column {
  display: flex; 
  justify-content: space-around;
  align-items: center;  
  color: #2D74E0; 
}

.technical_evaluation_consolidated .users_col .user_column:last-child { visibility: hidden; } 

.technical_evaluation_consolidated .users_scrollable_row .user_column .display_block { display: block; } /* ensuring line break for overflowed text */
  
.technical_evaluation_consolidated .summary_cols .auto_spacing_summary_col,
.technical_evaluation_consolidated .row.summary_label_cols {
  height: 50px;
}
/* Section Row Empty CSS */

.technical_evaluation_consolidated .section_row_empty { 
  display: block;
  height: 50px;
  background-color: #F1F6FF;
  left: 0;
  position: sticky; 
}

/* Vendors scoring column CSS */
 
.technical_evaluation_consolidated .spaced_scoring_col,
.technical_evaluation_consolidated .auto_spacing_summary_col { 
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #ffffff;
  border: 1px solid #E6E6E6;  
  display: flex; 
  justify-content: flex-start;
  align-items: center; 
}

.technical_evaluation_consolidated .auto_spacing_summary_col { width: 100% }

.technical_evaluation_consolidated .individual_space_column {  
  font-weight: 500; 
  font-size: 14px; 
  color: #000000;   
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.technical_evaluation_consolidated .individual_space_column img { 
  cursor: pointer;
  position: relative; 
}

.technical_evaluation_consolidated .dropdown-menu-comments { 
  background: #FFFFFF; 
  border: 1px solid #FFFFFF;
  box-shadow: 0px 4px 54px rgba(105, 105, 105, 0.25);
  padding: 1rem;
  z-index: 999;
  position: fixed; 
  width: 550px;
  height: auto;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
}

.technical_evaluation_consolidated .user_comments_tab > div > span{
  font-weight: 400;
  color: #2D74E0;
  font-size: 14px;
}

.technical_evaluation_consolidated .user_comments_tab > div > p { 
  color: #565656;
  font-size: 14px;
  font-weight: 400;
}

.technical_evaluation_consolidated .user_comments_tab > div { margin-bottom: 1rem; }

.technical_evaluation_consolidated .action_btn_close {
  display: flex;
  justify-content: end;
  align-items: center;
}

.technical_evaluation_consolidated .action_btn_close button {
  padding: 0.5rem 3rem;
  background-color: #2D74E0;
  color: #fff;
  border: none;
  border-radius: 4px;
}

.technical_evaluation_consolidated .dropdown-menu-comments h5 {
  font-size: 18px;
  color: #303030;
  text-align: start;
  font-weight: 600;
  margin-bottom: 1rem;
}

.technical_evaluation_consolidated .individual_space_column .form-group {margin-bottom: 1px; margin-top: 0;}

.technical_evaluation_consolidated .remove_last_item .individual_space_column:last-child { visibility: hidden; } 
 
/* Vendor List CSS */

.technical_evaluation_consolidated .vendor_col {  
  background-color: #F9F9F9;
  width: 100%;
}

.technical_evaluation_consolidated .vendor_div {    
  padding: 14px 10px;
  font-weight: 600;  
  font-size: 14px; 
  color: #000000; 
  display: flex; 
  justify-content: space-between;
  align-items: center; 
}

/* --- */


/* Team Evaluation Header - CSS */
 
.team_evaluation_criteria_header {
  position: sticky;
  top: 0;
  z-index: 7;
  display: grid;
  grid-template-columns: 300px auto;
  align-items: center; 
  height: auto;   
  /* user-select: none;   */
  border-top-right-radius: 8px;
  border-top-left-radius: 8px; 
  color: #fff;
  padding-right: 10px;
  padding-left: 10px;
  background-color: #273A59;
  cursor: pointer;
}

.team_evaluation_criteria_header .scrollable_column::-webkit-scrollbar,
.team_evaluation_criteria_header_cons .scrollable_column::-webkit-scrollbar{
  height: 3px;
  cursor: pointer;
}

.team_evaluation_criteria_header .scrollable_column::-webkit-scrollbar-track,
.team_evaluation_criteria_header_cons .scrollable_column::-webkit-scrollbar-track{
  background-color: #273A59;
  cursor: pointer;
}

.team_evaluation_criteria_header .scrollable_column::-webkit-scrollbar-thumb,
.team_evaluation_criteria_header_cons .scrollable_column::-webkit-scrollbar-thumb{
  background-color: #DEE8F4;
  border-radius: 10px;
  cursor: pointer;
}

.team_evaluation_criteria_header .fixed_column {
  font-weight: 500; 
  position: relative;
  /* z-index: 1; - uncomment if needed in fixed column */ 
  font-size: 16px;
}
 
.team_evaluation_criteria_header .scrollable_column {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  overflow-x: auto;
  font-weight: 500; 
  font-size: 16px;
  white-space: nowrap; 
}
 
.team_evaluation_criteria_header .child_div {
  min-width: 300px;
  height: 30px;
  display: inline-flex; 
  justify-content: center;
  align-items: center;
}

/* Team Evaluation Section/Criteria Collapse - bar */

  .criteria_section {
    padding: 20px 20px 0 20px;
    border-radius: 4px;
  }

  .criteria_section .rs_collapse {
    display: block;
    margin: 0;
    /* user-select: none;  */
    padding: 0px 20px 0 20px;
    border: 1px solid #DEE8F4;
    border-bottom: 0 none; 
  }

  .criteria_section  .rs_collapse .rs_collaps_title {
    margin: 0px -20px 0 -20px; 
    display: flex;
    justify-content: start !important;
    align-items: center;
    background-color: rgba(45, 116, 224, 0.06); 
    border-bottom: 1px solid #DEE8F4;
    cursor: pointer; 
  }

  .criteria_section .rs_collapse .rs_collaps_info {  
    margin-left: auto;
    display: flex;
    justify-content: end;
    align-items: center;
    cursor: pointer; 
  }

  .criteria_section .rs_collapse .rs_collaps_info h2 {
    display: block;
    margin: 0; 
    font-weight: 400;
    font-size: 15px;
    margin-left:80px;
    color: #000000; 
  }

  .criteria_section .rs_collapse .rs_collaps_info h2 span {
    font-weight: 600;
    color: #000000; 
  }

  .criteria_section  .rs_collapse .rs_collaps_title p {
    display: block;
    margin: 0;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    font-size: 18px;
    margin-left: 20px !important;   
    color: #000000; 
  }

  .criteria_section .rs_collapse .rs_collaps_title p span {
    color: #2D74E0;
  }

  .criteria_section  .rs_collapse .rs_collaps_title .cl_icon {
    display: block;
    position: relative;
    width: 15px;
    height: 8px; 
  }

  .criteria_section  .rs_collapse .rs_collaps_title .cl_icon:after {
    border-width: 8px;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    border-style: solid;
    border-color: transparent transparent #2D74E0  transparent; 
  }

  .criteria_section .rs_collapse .rs_collapse_contents {
    overflow-x: scroll;
    overflow: auto;
  }
    
  .criteria_section .rs_collapse .rs_collapse_contents {
    display: block;
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px;
    border-bottom: 1px solid #DEE8F4; 
  }

  .criteria_section  .rs_collapse.collapsed .rs_collaps_title .cl_icon:after {
    transform: rotate(180deg);
    top: 0;
    bottom: auto; 
  }

  .criteria_section  .rs_collapse.collapsed .rs_collapse_contents {
    display: none; 
  }

  .criteria_section  .rs_collapse + .rs_collapse {
    margin-top: 20px; 
  }
 
  /* Scoring Vendors Slider  */

  .range-wrap{
    width: auto;
    position: relative;
    display: flex;
    align-items: center; 
    padding-bottom: 4px;
    gap: 12px;
    padding-top: 4px;
    border-radius: 4px;
    margin-bottom: 1rem;
    border: 2px solid #2D74E0; 
  }

  .range-wrap span {
    font-size: 14px;
    color: #2D74E0;
    padding-right: 0.5rem;
    padding-left: 0.3rem;
  }

  .range-wrap input[type=range] {
    -webkit-appearance: none;
    margin: 18px 0;
    width: 100%;  
  }

  .range-wrap input[type=range]:focus {
    outline: none;
  }

  .range-wrap input[type=range]::-webkit-slider-runnable-track { 
    width: 100%; 
    height: 0px;
    cursor: pointer;
    animate: 0.2s;
    border: 0.5px dashed #2D74E0;
    border-radius: 25px;
  } 
 
  .range-wrap input[type=range]::-webkit-slider-thumb { 
    appearance: none;  
    height: 28px;
    width: 40px;
    background: transparent;
    margin-top: -13px; 
  }
 
  .range-wrap input[type=range]::-moz-range-thumb {  
    height: 28px;
    width: 40px;
    background: transparent;
    margin-top: -13px; 
  }

  .range-wrap input[type=range]::-ms-thumb {  
    height: 28px;
    width: 40px;
    background: transparent;
    margin-top: -13px; 
  }

  .range-wrap input[type=range]:focus::-webkit-slider-runnable-track { border: 0.5px dashed #2D74E0; }

  .range-value { 
    position: absolute;
    top: 11px; 
    pointer-events: none;
    cursor: pointer;
  }
 
  .range-value span {
    height: 27px;
    width: 27px;
    line-height: 27px;
    text-align: center;
    background: #2D74E0;
    color: #fff;
    font-size: 14px;
    display: block;
    position: absolute;
    cursor: pointer;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 50%;
  }

  /* Scrollable Container Criteria Section's Wrapper  */

  .sections_cn  {
    height: auto;
    overflow: auto; 
  }

  .sections_cn .container-fluid {
    max-height: 495px;  
    max-width: 100%;   
  }
  
  .sections_cn::-webkit-scrollbar {
    width: 3px;
    height: 5px; 
    cursor: pointer;
  } 

  .sections_cn:hover::-webkit-scrollbar {
    width: 8px;
  }

  .sections_cn::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .sections_cn::-webkit-scrollbar-track {
    background-color: #E6E6E6;
    cursor: pointer;
  }

  .sections_cn .row {
    display: flex;
    flex-wrap: nowrap;  
    width: max-content;  
  }

  .sections_cn .column {
    width: 33.33%; /* occupy one-third of the width of the parent container */
    min-width: 300px;
    max-width: 301px;
    height: 165px;
    flex-shrink: 0; /* prevent shrinking */ 
    border-bottom: 1px solid #DCDFE3;
    border-right: 1px solid #DCDFE3;
    padding-top: 10px;  
    padding-bottom: 10px;
    position: relative;
  }

  .technical_evaluation_section .sections_cn .row .section-head {
    display: flex;
    flex-direction: column;  
    justify-content: start;
    align-items: flex-start; 
    padding: 35px 25px; 
    background: #fff;
    position: sticky;
    left: -10px;
    z-index: 1;
    max-height: 165px;  
    overflow: hidden;
  }

  .technical_evaluation_section  .sections_cn .row .section-head h1 {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 8px; 
    margin-top: 12px; 
  }

  .technical_evaluation_section  .sections_cn .row .section-head p {
    font-size: 0.9rem;
    font-weight: 500;
    max-height: 80px; 
    overflow-y: auto; 
    margin: 0; 
    padding-right: 10px;  
  }

  .technical_evaluation_section .checkbox-applicable {
    position: absolute;
    top: 25px;  
    right: 0;  
    transform: translate(3%, -30%);  
    display: flex;
    align-items: center;
    justify-content: center; 
  }

  .sections_cn .row .column .textarea-for-comments {
    border: 2px solid #DEE8F4;
    border-radius: 4px;
    width: 100%;
    height: 80px;
    resize: none;
    max-height: 80px;
    min-height: 80px;
  }

  .sections_cn .row .column  .default {
    border: 2px solid #DEE8F4; 
  }

  .sections_cn .row .column  .required {
    border: 2px solid #E94335;
  }

  .sections_cn .row .column .textarea-for-comments:focus {
    outline: none;
  }

  .sections_cn .row .column > div:last-child { 
    border-right: 0;
  }
 
  /* Default Styles (for larger screens) */
  .technical_evaluation_module_wrapper .lynkaz_tab .lynkaz_tab_contents .lynkaz_tab_contents_content {
    width: 60%;
  }

  .technical_evaluation_module {
    width: auto;
    margin-bottom: 3rem;
  }
  
  .technical_evaluation_section {
    border: 1px solid #E6E6E6;
  }

  .tech_evaluation_master_wrapper {
    padding: 0;
    margin: 0;
    border-radius: 8px;
    min-height: 600px;
    max-height: auto;
    padding-bottom: 20px;
    height: auto;
    border: 1px solid #E6E6E6;
  }

/* Technical Evaluation With Consolidated View - CSS */

.technical_evaluation_filtering {
  display: flex;
  justify-content: start;
  width: 280px;
  align-items: center;
  gap: 30px;
  padding: 15px 0;
}

.technical_evaluation_filtering .technical_evaluation_preview_tab_wrapper img {
  width: 25px;
  height: auto;
  cursor: pointer;
}

.technical_evaluation_filtering .user_for_scoring_dropdown {
  flex-grow: 1;
}

 
@media (max-width: 1024px) {
  .technical_evaluation_module_wrapper .lynkaz_tab .lynkaz_tab_contents .lynkaz_tab_contents_content {
    width: 70%;
  }

  .technical_evaluation_module {
    margin-bottom: 2rem;
  }
 
  .technical_evaluation_filtering {
    width: 240px;
    gap: 20px;
    padding: 10px 0;
  }

  .technical_evaluation_filtering .user_for_scoring_dropdown {
    flex-grow: 1;
  }
}
 
@media (max-width: 768px) {
  .technical_evaluation_module_wrapper .lynkaz_tab .lynkaz_tab_contents .lynkaz_tab_contents_content {
    width: 100%;
  }

  .technical_evaluation_module {
    margin-bottom: 1.5rem;
  }

  .technical_evaluation_with_consolidated_view_wrapper {
    width: 100%;
  }

  .technical_evaluation_filtering {
    width: 200px;
    gap: 15px;
    padding: 8px 0;
  }

  .technical_evaluation_filtering .user_for_scoring_dropdown {
    flex-grow: 1;
  }

  .technical_evaluation_consolidated {
    height: 50vh;
  }

  .technical_evaluation_consolidated .grid-container {
    grid-template-columns: 1fr;
  }

  .technical_evaluation_consolidated .column {
    max-height: 600px;
  }

  .technical_evaluation_consolidated .evaluation-criteria-heading,
  .technical_evaluation_consolidated .evaluation-criterias,
  .technical_evaluation_consolidated .evaluation-summaries,
  .technical_evaluation_consolidated .evaluation-all-scores {
    font-size: 12px;
  }
}

/* Extra small screens (less than 600px) */
@media (max-width: 600px) {
  .technical_evaluation_module_wrapper .lynkaz_tab .lynkaz_tab_contents .lynkaz_tab_contents_content {
    width: 100%;
  }

  .technical_evaluation_module {
    margin-bottom: 1rem;
  }

  .technical_evaluation_with_consolidated_view_wrapper {
    width: 100%;
  }

  .technical_evaluation_filtering {
    width: 100%;
    flex-direction: column;
    gap: 10px;
    padding: 5px 0;
  }

  .technical_evaluation_filtering .user_for_scoring_dropdown {
    width: 100%;
  }

  .technical_evaluation_consolidated {
    height: 40vh;
  }

  .technical_evaluation_consolidated .grid-container {
    grid-template-columns: 1fr;
  }

  .technical_evaluation_consolidated .column {
    max-height: 400px;
  }

  .technical_evaluation_consolidated .evaluation-criteria-heading,
  .technical_evaluation_consolidated .evaluation-criterias,
  .technical_evaluation_consolidated .evaluation-summaries,
  .technical_evaluation_consolidated .evaluation-all-scores {
    font-size: 10px;
  }
}
